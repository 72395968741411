<template>
    <div>
      <b-card
      tag="article"
      style="max-width: 20rem;"
      class="notificationPrompt"
      v-if="showPrompt()"
  >
    <b-card-text>
     You have push notification enabled on this user account.
     do you want to enable it on this browser?
    </b-card-text>
    <div class="d-flex justify-content-between">
    <b-button @click="handleYes" variant="primary">{{messages.getNotificationPromptYesText()}}</b-button>
    <b-button @click="handleNo" variant="primary">{{ messages.getNotificationPromptNoText() }}</b-button>
    <b-button @click="handleNever" variant="secondary">{{ messages.getNotificationPromptNeverText() }}</b-button>
  </div>
  </b-card>
    </div>
</template>

<script>
/* global Notification */
import { mapGetters } from 'vuex'
import { NOTIFICATION_SETTINGS, NOTIFICATION_PUSH_NOTIFICATION } from '@/constants/store-names'
import { YES, NO, NEVER } from '@/constants/notifications'
import { messages } from '@/utils/strings'
import { DEVICE_ID, FCM_TOKEN } from '@/constants/system-info'
export default {
  data () {
    return {
      promptAnswer: null || localStorage.getItem('promptNotification'),
      messages
    }
  },
  computed: {
    ...mapGetters({
      notificationSettings: `${NOTIFICATION_SETTINGS}/notificationSettings`,
      userProfilePushNotificationToggled: `${NOTIFICATION_SETTINGS}/getUserProfilePushNotificationToggled`
    }),
    primarySettings () {
      return this.notificationSettings.find((settings) => settings.primary)
    },
    isFirefoxBrowser () {
      return navigator.userAgent.toLowerCase().indexOf('firefox') > -1
    }
  },
  methods: {
    showPrompt () {
      return this.primarySettings?.pushNotificationsEnabled && this.isFirefoxBrowser && !this.promptAnswer && Notification.permission === 'default' && !this.userProfilePushNotificationToggled
    },
    handleYes () {
      this.promptAnswer = YES
      this.promptPermission()
    },
    handleNo () {
      this.promptAnswer = NO
    },
    handleNever () {
      this.promptAnswer = NEVER
      localStorage.setItem('promptNotification', NEVER)
    },
    promptPermission () {
      const primarySettings = this.notificationSettings.find((settings) => settings.primary)
      // eslint-disable-next-line no-undef
      if (primarySettings && primarySettings.pushNotificationsEnabled) {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            this.getFCMToken()
          }
        })
      }
    },
    getFCMToken () {
      const messaging = this.$firebase.messaging()
      messaging.getToken()
        .then((currentToken) => {
          if (currentToken) {
            this.$store.commit(`${NOTIFICATION_SETTINGS}/updateDeviceById`, {
              deviceId: localStorage.getItem(DEVICE_ID),
              newDeviceInfo: {
                isLoggedIn: true,
                token: currentToken
              }
            })
            this.$store.dispatch(`${NOTIFICATION_SETTINGS}/updateNotificationSettingsNoLoading`, this.$store.state.profile.uid)
            localStorage.setItem(FCM_TOKEN, currentToken)
          } else {
            console.log('No FCM token available. Request permission to generate one.')
          }
        })
        .catch((error) => {
          console.error('Error getting FCM token:', error)
        })
    },
    handleOnMessage () {
      const messaging = this.$firebase.messaging()
      messaging.onMessage((payload) => {
        const messageJson = payload.data
        this.$bvToast.toast(messageJson.message_body, {
          title: `${messageJson.title}`,
          toaster: 'b-toaster-top-right',
          solid: true,
          appendToast: false,
          variant: 'info',
          autoHideDelay: 10000
        })
        this.$store.dispatch(`${NOTIFICATION_PUSH_NOTIFICATION}/markAsRead`, messageJson.notification_id)
      })
    },
    deviceIdCheck () {
      if (!localStorage.getItem(DEVICE_ID)) {
        localStorage.setItem(
          DEVICE_ID,
          Math.floor(Date.now() * Math.random(0, 100))
        )
      }
    }
  },
  async created () {
    await this.$store.dispatch(`${NOTIFICATION_SETTINGS}/fetchNotificationSettings`, this.$store.state.profile.uid)
    this.promptPermission()
    this.handleOnMessage()
  },
  mounted () {
    this.deviceIdCheck()
  }
}
</script>

<style scoped>
/* Your CSS code here */
.notificationPrompt {
  position: fixed;
  top: 0;
  right: 0;
  margin: 19px;
  z-index: 9999;
}
</style>
