export const NOTIFICATIONS = 'notifications'
export const NOTIFICATION_DROPDOWN = `${NOTIFICATIONS}/dropdown`
export const NOTIFICATION_SETTINGS = `${NOTIFICATIONS}/settings`
export const NOTIFICATION_EMAIL = `${NOTIFICATIONS}/email`
export const NOTIFICATION_SMS = `${NOTIFICATIONS}/sms`
export const NOTIFICATION_FEEDBACK = `${NOTIFICATIONS}/feedback`
export const NOTIFICATION_PUSH_NOTIFICATION = `${NOTIFICATIONS}/pushNotification`
export const NOTIFICATION_RATING_FEEDBACK_FORM = `${NOTIFICATION_FEEDBACK}/ratingFeedbackForm`
export const NOTIFICATION_FEEDBACK_REPORT_ISSUE = `${NOTIFICATION_FEEDBACK}/reportIssue`
export const NOTIFICATION_FEEDBACK_RUO = `${NOTIFICATION_FEEDBACK}/requestUpdateOrder`
export const NOTIFICATION_FEEDBACK_RST = `${NOTIFICATION_FEEDBACK}/requestStockTransfer`
export const NOTIFICATION_FEEDBACK_RSD = `${NOTIFICATION_FEEDBACK}/reportStockDifference`
export const NOTIFICATION_FEEDBACK_RAPCN = `${NOTIFICATION_FEEDBACK}/requestUpdateAccessPointContactNotification`
