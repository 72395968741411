import {
  SUPER_MANAGER,
  SUPER_MANAGER_READ_ONLY,
  STOCK_ADMIN,
  SUPERVISOR
} from '@/constants/permissions'
import {
  hasCommonElement
} from '@/utils/helper_functions'
import { getNotificationSettingsApi, putNotificationSettingsApi } from '@/store/notifications/logistics-apis'
import Vue from 'vue'
import { getSystemInfo } from '@/utils/system-info.js'
import { DEVICE_ID, FCM_TOKEN } from '@/constants/system-info'
export default {
  namespaced: true,
  state: {
    notificationSettings: [],
    ongoingQuery: false,
    firstFetchDone: false,
    canSaveNotificationSettings: true,
    userProfilePushNotificationToggled: false
  },
  getters: {
    getUserProfilePushNotificationToggled: (state) => {
      return state.userProfilePushNotificationToggled
    },
    userSearchOptions: (state) => {
      return state.userSearchOptions
    },
    notificationSettings: (state) => {
      return state.notificationSettings
    },
    getUserProfile: (state, getters, rootState) => {
      return rootState.profile
    },
    ongoingQuery: (state) => {
      return state.ongoingQuery
    },
    firstFetchDone: (state) => {
      return state.firstFetchDone
    },
    canSaveNotificationSettings: (state) => {
      return state.canSaveNotificationSettings
    },
    inAllowedNotificationPlants: (state, getters, rootState, rootGetters) => {
      const userPermissions = getters.getUserProfile.permissions
      return hasCommonElement(userPermissions, [SUPER_MANAGER, SUPER_MANAGER_READ_ONLY]) ||
             hasCommonElement(rootGetters.getAllowedPlants.notificationsAllowedPlants, getters.getUserProfile.plants)
    },
    canEditNotificationSettings: (state, getters) => {
      const userPermissions = getters.getUserProfile.permissions
      if (hasCommonElement(userPermissions, [SUPER_MANAGER, SUPERVISOR, STOCK_ADMIN])) {
        return true
      }
      return false
    },
    notificationsAllowedPlants: (state, getters, rootState, rootGetters) => {
      return rootGetters.getAllowedPlants.notificationsAllowedPlants
    }
  },
  mutations: {
    setUserProfilePushNotificationToggled (state, userProfilePushNotificationToggled) {
      state.userProfilePushNotificationToggled = userProfilePushNotificationToggled
    },
    updateDeviceById (state, { deviceId, newDeviceInfo }) {
      const primarySettings = state.notificationSettings.find((setting) => setting.primary)
      const currentDevice = primarySettings?.pushNotificationsSettings?.find(device => device.deviceId === deviceId)
      const systemInfo = getSystemInfo()
      const device = {
        isEnabled: newDeviceInfo.isEnabled ?? currentDevice?.isEnabled ?? true,
        token: newDeviceInfo.token ?? currentDevice?.token ?? localStorage.getItem(FCM_TOKEN) ?? '',
        deviceName: newDeviceInfo.deviceName ?? currentDevice?.deviceName ?? `${systemInfo.OSName}/${systemInfo.browserName}`,
        isLoggedIn: newDeviceInfo.isLoggedIn ?? currentDevice?.isLoggedIn ?? true,
        deviceId: localStorage.getItem(DEVICE_ID)
      }
      if (primarySettings && !primarySettings.pushNotificationsSettings) {
        Vue.set(primarySettings, 'pushNotificationsSettings', [device]) // this insures that the new array is reactive
        return
      }
      if (currentDevice) {
        Object.assign(currentDevice, device)
      } else {
        primarySettings && primarySettings.pushNotificationsSettings.push(device)
      }
    },
    updateEnabledDevices (state, deviceIds) {
      const primarySettings = state.notificationSettings.find((setting) => setting.primary)
      primarySettings.pushNotificationsSettings.forEach((device) => {
        if (deviceIds.includes(device.deviceId)) {
          device.isEnabled = true
        } else {
          device.isEnabled = false
        }
      })
    },
    startQuery (state) {
      state.ongoingQuery = true
    },
    finishQuery (state) {
      state.ongoingQuery = false
      state.firstFetchDone = true
    },
    setNotificationSettings (state, { response }) {
      state.notificationSettings = response.data.notification_settings
    },
    addItemToNotificationSettings (state, { item }) {
      state.notificationSettings.push(item)
    },
    removeItemFromNotificationSettings (state, { index }) {
      state.notificationSettings.splice(index, 1)
    },
    setCanSaveNotificationSettings (state, canSave) {
      state.canSaveNotificationSettings = canSave
    }

  },
  actions: {
    fetchNotificationSettings ({ commit, getters }, userId) {
      commit('startQuery')
      const requestPromise = getNotificationSettingsApi(userId)
        .then((response) => {
          commit('setNotificationSettings', { response })
        })
        .catch((error) => {
          console.error({ _: 'Get Notification Preferences failed', error })
        }).finally(() => {
          commit('finishQuery')
        })
      return requestPromise
    },
    updateNotificationSettings ({ commit, getters }, userId) {
      const notificationPreferences = getters.notificationSettings
      if (notificationPreferences.length === 0) return
      commit('startQuery')
      const requestPromise = putNotificationSettingsApi(userId, notificationPreferences)
        .then((response) => {
          commit('setNotificationSettings', { response })
          return response
        })
        .catch(error => {
          commit('setErrorMessage', error?.response?.data?.message ?? error, { root: true })
        })
        .finally(() => {
          commit('finishQuery')
        })
      return requestPromise
    },
    updateNotificationSettingsNoLoading ({ commit, getters }, userId) {
      const notificationPreferences = getters.notificationSettings
      if (notificationPreferences.length === 0) return
      const requestPromise = putNotificationSettingsApi(userId, notificationPreferences)
        .then((response) => {
          commit('setNotificationSettings', { response })
          return response
        })
        .catch(error => {
          commit('setErrorMessage', error?.response?.data?.message ?? error, { root: true })
        })
      return requestPromise
    },
    addNotificationSettingItem ({ commit, getters }, userEmail) {
      const notificationPreferences = getters.notificationSettings
      const item = {
        primary: false,
        emailEnabled: false,
        email: '',
        mobileEnabled: false,
        mobileNumber: '',
        pushNotificationsEnabled: false
      }
      if (notificationPreferences.length === 0) {
        item.primary = true
        item.email = userEmail
      }
      commit('addItemToNotificationSettings', { item })
    },
    async removeNotificationSettingItem ({ commit }, { index }) {
      commit('removeItemFromNotificationSettings', { index })
    }
  }
}
