<template>
    <b-modal  size='lg' id="showPushNotificationModal" content-class="content-class"  hide-footer style="top:125px">
      <template #modal-title>
        <label style="font-weight: 400; font-size: 24px;"> {{messages.getLabelPushNotification()}} </label>
      </template>

        <b-list-group flush>
          <b-list-group-item borderless>
            <div>
              <i class="icon-message text-primary modal-icon"></i>
              <label>{{messages.getLabelEmail()}}</label>
            </div>
            <p class="content-box">{{ push.user_email }}</p>

          </b-list-group-item>
          <b-list-group-item>
            <label> {{messages.getLabelTitle()}}</label>
            <p class="underline">{{ push.title }}</p>
            <div>
              <i class="icon-tooltip text-primary modal-icon"></i>
              <label>{{messages.getLabelMessage()}}</label>
            </div>
            <p class="content-box" v-html="renderMessageWithLinks(push.message)"></p>
          </b-list-group-item>
        </b-list-group>

    </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { messages } from '@/utils/strings'
import { NOTIFICATION_PUSH_NOTIFICATION } from '@/constants/store-names'
import { renderMessageWithLinks } from '@/utils/string-manipulation'
export default {
  name: 'ViewPushModal',
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      messages
    }
  },
  computed: {
    ...mapGetters({
      push: `${NOTIFICATION_PUSH_NOTIFICATION}/item`
    })
  },
  methods: {
    renderMessageWithLinks
  }
}
</script>

<style  >
.modal-icon {
  font-size: 1.2rem;
  margin-right: 10px;
}
.content-box {
  background-color: #EEF0F1;
  padding: 5px;
  border-radius: 5px;
  width: fit-content;
}
.underline {
  padding: 5px;
  border-bottom: 1px solid #E0E4E6;
  width: fit-content;
}
#showPushNotificationModal___BV_modal_outer_ {
  position: initial !important;
}
.content-class {
  top: 125px !important;
}
</style>
