import { enrichTrackingStatusItems } from './response-mappers'
import {
  calculateReservationQuantitySum, getPurchaseOrderItemQuantity,
  calculateSalesOrderScheduleLineAndDeliveryQuantitySum, trackingStatusStages
} from './tracking-status'

function trackingStatusItemsToRows (trackingStatusResponseItems) {
  const trackingStatusItems = enrichTrackingStatusItems(trackingStatusResponseItems)
  const orderRows = []
  for (const trackingStatus of trackingStatusItems) {
    const orderRow = {
      rowType: 'orderRow',
      trackingStatusItem: trackingStatus, // Raw enriched item
      showExpandRows: false // Switch for template to render expanded rows. User toggleable.
    }
    // All rows in mobile UI are expandable so the check for serviceOrder moved in OrderTable
    orderRow.expandRows = getExpandRowsFromTrackingStatus(trackingStatus)
    orderRows.push(orderRow)
  }
  return orderRows
}

/* eslint-disable no-unused-vars */
function getExpandRowsFromTrackingStatus (trackingStatus) {
  let trackingStatusRows = []
  const itemsByMaterialCode = groupItemsByMaterialCode(trackingStatus)
  for (const [materialCode, items] of Object.entries(itemsByMaterialCode)) {
    const rowsForMaterialCode = createRowsForMaterialCode(materialCode, items)
    trackingStatusRows = trackingStatusRows.concat(rowsForMaterialCode)
  }
  return trackingStatusRows
}

/**
 * Create expand rows grouped under a material code.
 */
function createRowsForMaterialCode (materialCode, items) {
  const materialCodeRow = createMaterialCodeRow(materialCode, items)
  const reservationRow = createReservationRow(materialCode, items)
  const materialCodeItemRows = createMaterialCodeItemRows(materialCode, items, reservationRow)
  return [materialCodeRow, reservationRow, ...materialCodeItemRows]
}

/**
 * Create material code expand row.
 */
function createMaterialCodeRow (materialCode, items) {
  const trackingNumber = items.find(item => item.trackingNumber)?.trackingNumber
  const rowObject = {
    rowType: 'materialCodeGroup',
    materialCode: materialCode || null,
    trackingNumber
  }
  return rowObject
}

/**
 * Create expand rows for items in a material code group.
 */
function createMaterialCodeItemRows (materialCode, items, reservationRow) {
  const materialCodeItemRows = []
  for (const item of items) {
    const itemRow = { ...item }
    itemRow.rowType = 'materialCodeItem'
    itemRow._reservationQuantitySum = reservationRow._reservationQuantitySum
    itemRow._purchaseOrderQuantity = reservationRow._purchaseOrderQuantity
    itemRow._salesOrderScheduleLineAndDeliveryQuantitySum = reservationRow._salesOrderScheduleLineAndDeliveryQuantitySum
    materialCodeItemRows.push(itemRow)
  }
  return materialCodeItemRows
}

/**
 * Create object for the reservation expand row
 *
 * @param {string} materialCode - Material code from Tracking Status
 * @param {array} trackingStatusItems - Items-like from Tracking Status items array.
 */
function createReservationRow (materialCode, trackingStatusItems) {
  const reservationRow = {
    rowType: 'reservationRow',
    _salesOrderScheduleLineAndDeliveryQuantitySum: calculateSalesOrderScheduleLineAndDeliveryQuantitySum(trackingStatusItems),
    _purchaseOrderQuantity: getPurchaseOrderItemQuantity(trackingStatusItems),
    _reservationQuantitySum: calculateReservationQuantitySum(trackingStatusItems),
    _materialDescription: trackingStatusItems[0].materialDescription,
    _unit: trackingStatusItems[0].unit
  }
  return reservationRow
}

function groupItemsByMaterialCode (response) {
  // const itemsWithMaterialCodes = response.items.filter(item => item.materialCode)
  const allItems = response.items
  const uniqueMaterialCodes = new Set(allItems.map(item => item.materialCode))
  const itemsByMaterialCode = {}
  for (const materialCode of Array.from(uniqueMaterialCodes)) {
    const matchingItems = response.items.filter(item => item.materialCode === materialCode)
    itemsByMaterialCode[materialCode] = matchingItems
  }
  return itemsByMaterialCode
}

function quantityForStage (stage, trackingStatusItem) {
  const noQuantityStr = ''

  // There is no quantity associated with a Service Order.
  if (stage === trackingStatusStages.SERVICE_ORDER) {
    return noQuantityStr
  }

  // This function is for non-expandable rows, which have only one subitem.
  if (trackingStatusItem?.items?.length !== 1) {
    console.warn('Computing quantity for a non-expandable row. Expected a row with exactly one item.')
    return noQuantityStr
  }
  const firstItem = trackingStatusItem?.items?.[0]

  // Unit does no depend on stage.
  const unit = (firstItem.unit || '')

  // Quantities are recorded in stage-specific variables. Choose the current one.
  let quantity = null
  switch (stage) {
    case trackingStatusStages.RESERVATION_ITEM:
      quantity = firstItem.reservationQuantity
      break
    case trackingStatusStages.DELIVERY_ORDER_ITEM:
      quantity = firstItem.deliveryQuantity
      break
    case trackingStatusStages.PURCHASE_ORDER_ITEM:
      quantity = firstItem.purchaseOrderQuantity
      break
    case trackingStatusStages.PURCHASE_ORDER_CONFIRMATION_ITEM:
      if (firstItem.purchaseOrderConfirmationQuantity === firstItem.purchaseOrderQuantity) {
        quantity = firstItem.purchaseOrderConfirmationQuantity
      } else {
        // If purchaseOrderConfirmationQuantity is not the same purchaseOrderQuantity then we should show
        // fraction. This requirement is from customer during beta testing.
        quantity = `${firstItem.purchaseOrderConfirmationQuantity}/${firstItem.purchaseOrderQuantity}`
      }
      break
    case trackingStatusStages.PURCHASE_ORDER_GOODS_RECEIPT_ITEM:
      if (firstItem.purchaseOrderGoodsReceiptQuantity === firstItem.purchaseOrderQuantity) {
        quantity = firstItem.purchaseOrderGoodsReceiptQuantity
      } else {
        // If purchaseOrderConfirmationQuantity is not the same purchaseOrderQuantity then we should show
        // fraction. This requirement is from customer during beta testing.
        quantity = `${firstItem.purchaseOrderGoodsReceiptQuantity}/${firstItem.purchaseOrderQuantity}`
      }
      break
    case trackingStatusStages.SALES_ORDER_ITEM:
      quantity = firstItem.salesOrderQuantity
      break
    case trackingStatusStages.SALES_ORDER_SCHEDULE_LINE_ITEM:
      quantity = firstItem.salesOrderScheduleLineQuantity
      break
    default:
      console.warn({ _: 'Don\'t know how to determine quantity for the given stage', stage })
      return noQuantityStr
  }

  // Sometimes quantity is missing in SAP, which should make quantity undefined. Don't show anything.
  if (quantity === undefined || quantity === null) {
    return noQuantityStr
  }

  return quantity + ' ' + unit
}

export { trackingStatusItemsToRows, quantityForStage }
