/**
 * Contains a mapping of actions that a user may perform based on who they are.
 *
 * We try to follow the example of GCP as closely as possible, although
 * currently our terminology is a bit wrong:
 * - GCP role = KPT permission
 *   - Example: "Admin"
 * - GCP permission = KPT permitted action
 *   - Example: "resourcemanager.projects.get"
 *
 * Notes:
 * - "get" means to view.
 *
 * @see https://cloud.google.com/iam/docs/understanding-roles
 */

import deepFreeze from '@/utils/deep-freeze'

export const stockManagement = {
  get: 'stockManagement.get'
}
deepFreeze(stockManagement)

export const userManagement = {
  get: 'userManagement.get',
  allUsers: {
    list: 'userManagement.allUsers.list'
  },
  approvedUsers: {
    list: 'userManagement.approvedUsers.list'
  },
  myPlantUsers: {
    list: 'userManagement.myPlantUsers.list'
  },
  user: {
    delete: 'userManagement.user.delete',
    permissions: {
      /**
       * This refers to being able to open the permissions dialog.
       */
      get: 'userManagement.user.permissions.get',
      /**
       * This refers to being able to save on the permissions dialog.
       */
      update: 'userManagement.user.permissions.update',
      pmRead: {
        get: 'userManagement.user.permissions.pmRead.get',
        update: 'userManagement.user.permissions.pmRead.update'
      },
      pmWrite: {
        get: 'userManagement.user.permissions.pmWrite.get',
        update: 'userManagement.user.permissions.pmWrite.update'
      },
      pmUploadDisable: {
        get: 'userManagement.user.permissions.pmUploadDisable.get',
        update: 'userManagement.user.permissions.pmUploadDisable.update'
      },
      pmUploadAdd: {
        get: 'userManagement.user.permissions.pmUploadAdd.get',
        update: 'userManagement.user.permissions.pmUploadAdd.update'
      },
      umPmGrantWrite: {
        get: 'userManagement.user.permissions.umPmGrantWrite.get',
        update: 'userManagement.user.permissions.umPmGrantWrite.update'
      },
      umPmGrantRead: {
        get: 'userManagement.user.permissions.umPmGrantRead.get',
        update: 'userManagement.user.permissions.umPmGrantRead.update'
      },
      umUserAcceptance: {
        get: 'userManagement.user.permissions.umUserAcceptance.get',
        update: 'userManagement.user.permissions.umUserAcceptance.update'
      },
      superManager: {
        get: 'userManagement.user.permissions.superManager.get',
        update: 'userManagement.user.permissions.superManager.update'
      },
      mechanic: {
        get: 'userManagement.user.permissions.mechanic.get',
        update: 'userManagement.user.permissions.mechanic.update'
      },
      customServiceAdmin: {
        get: 'userManagement.user.permissions.customServiceAdmin.get',
        update: 'userManagement.user.permissions.customServiceAdmin.update'
      },
      supervisor: {
        get: 'userManagement.user.permissions.supervisor.get',
        update: 'userManagement.user.permissions.supervisor.update'
      },
      stockAdmin: {
        get: 'userManagement.user.permissions.stockAdmin.get',
        update: 'userManagement.user.permissions.stockAdmin.update'
      },
      superManagerReadOnly: {
        get: 'userManagement.user.permissions.superManagerReadOnly.get',
        update: 'userManagement.user.permissions.superManagerReadOnly.update'
      },
      plants: {
        get: 'userManagement.user.permissions.plants.get',
        have: 'userManagement.user.permissions.plants.have',
        update: 'userManagement.user.permissions.plants.update'
      },
      plannerGroups: {
        get: 'userManagement.user.permissions.plannerGroups.get',
        have: 'userManagement.user.permissions.plannerGroups.have',
        update: 'userManagement.user.permissions.plannerGroups.update'
      },
      shipToPartyNumbers: {
        get: 'userManagement.user.permissions.shipToPartyNumbers.get',
        have: 'userManagement.user.permissions.shipToPartyNumbers.have',
        update: 'userManagement.user.permissions.shipToPartyNumbers.update'
      },
      /**
       * Note: there is no "storageLocations.have" because all users can have
       * storage locations.
       */
      storageLocations: {
        get: 'userManagement.user.permissions.storageLocations.get',
        update: 'userManagement.user.permissions.storageLocations.update'
      },
      workCenters: {
        get: 'userManagement.user.permissions.workCenters.get',
        have: 'userManagement.user.permissions.workCenters.have',
        update: 'userManagement.user.permissions.workCenters.update'
      },
      createStockCount: {
        get: 'userManagement.user.permissions.createStockCount.get',
        update: 'userManagement.user.permissions.createStockCount.update'
      },
      addMaterialCount: {
        get: 'userManagement.user.permissions.addMaterialCount.get',
        update: 'userManagement.user.permissions.addMaterialCountCount.update'
      },
      deleteMaterialCount: {
        get: 'userManagement.user.permissions.deleteMaterialCount.get',
        update: 'userManagement.user.permissions.deleteMaterialCount.update'
      },
      editMaterialCountQuantity: {
        get: 'userManagement.user.permissions.editMaterialCountQuantity.get',
        update: 'userManagement.user.permissions.editMaterialCountQuantity.update'
      },
      submitStockCount: {
        get: 'userManagement.user.permissions.submitStockCount.get',
        update: 'userManagement.user.permissions.submitStockCount.update'
      },
      cancelStockCount: {
        get: 'userManagement.user.permissions.cancelStockCount.get',
        update: 'userManagement.user.permissions.cancelStockCount.update'
      },
      editStockCount: {
        get: 'userManagement.user.permissions.editStockCount.get',
        update: 'userManagement.user.permissions.editStockCount.update'
      },
      shipToParty: {
        get: 'userManagement.user.permissions.shipToParty.get',
        update: 'userManagement.user.permissions.shipToParty.update',
        have: 'userManagement.user.permissions.shipToParty.have'
      },
      requestStockTransfer: {
        get: 'userManagement.user.permissions.requestStockTransfer.get',
        update: 'userManagement.user.permissions.requestStockTransfer.update'
      }
    },
    expirationDate: {
      update: 'userManagement.user.expirationDate.update'
    }
  }
}
deepFreeze(userManagement)

const permittedActionsForPermission = {
  PM_read: [],
  PM_write: [
    userManagement.user.permissions.shipToPartyNumbers.have
  ],
  PM_upload_add: [],
  PM_upload_disable: [],
  UM_user_acceptance: [
    userManagement.get,
    userManagement.approvedUsers.list,
    userManagement.allUsers.list,
    userManagement.user.delete,
    userManagement.user.expirationDate.update
  ],
  UM_PM_grant_read: [
    userManagement.user.permissions.get,
    userManagement.user.permissions.update,
    userManagement.user.permissions.pmRead.get,
    userManagement.user.permissions.pmRead.update,
    userManagement.user.permissions.plants.get,
    userManagement.user.permissions.plants.update,
    userManagement.user.permissions.plannerGroups.get,
    userManagement.user.permissions.plannerGroups.update,
    userManagement.user.permissions.shipToPartyNumbers.get,
    userManagement.user.permissions.shipToPartyNumbers.update,
    userManagement.user.permissions.workCenters.get,
    userManagement.user.permissions.workCenters.update
  ],
  UM_PM_grant_write: [
    userManagement.user.permissions.get,
    userManagement.user.permissions.update,
    userManagement.user.permissions.pmWrite.get,
    userManagement.user.permissions.pmWrite.update,
    userManagement.user.permissions.plants.get,
    userManagement.user.permissions.plants.update,
    userManagement.user.permissions.plannerGroups.get,
    userManagement.user.permissions.plannerGroups.update,
    userManagement.user.permissions.shipToPartyNumbers.get,
    userManagement.user.permissions.shipToPartyNumbers.update,
    userManagement.user.permissions.workCenters.get,
    userManagement.user.permissions.workCenters.update
  ],
  super_manager: [
    stockManagement.get,
    userManagement.get,
    userManagement.allUsers.list,
    userManagement.approvedUsers.list,
    userManagement.user.delete,
    userManagement.user.permissions.get,
    userManagement.user.permissions.update,
    userManagement.user.permissions.pmRead.get,
    userManagement.user.permissions.pmRead.update,
    userManagement.user.permissions.pmWrite.get,
    userManagement.user.permissions.pmWrite.update,
    userManagement.user.permissions.pmUploadDisable.get,
    userManagement.user.permissions.pmUploadDisable.update,
    userManagement.user.permissions.pmUploadAdd.get,
    userManagement.user.permissions.pmUploadAdd.update,
    userManagement.user.permissions.umPmGrantWrite.get,
    userManagement.user.permissions.umPmGrantWrite.update,
    userManagement.user.permissions.umPmGrantRead.get,
    userManagement.user.permissions.umPmGrantRead.update,
    userManagement.user.permissions.umUserAcceptance.get,
    userManagement.user.permissions.umUserAcceptance.update,
    userManagement.user.permissions.superManager.get,
    userManagement.user.permissions.superManager.update,
    userManagement.user.permissions.mechanic.get,
    userManagement.user.permissions.mechanic.update,
    userManagement.user.permissions.customServiceAdmin.get,
    userManagement.user.permissions.customServiceAdmin.update,
    userManagement.user.permissions.supervisor.get,
    userManagement.user.permissions.supervisor.update,
    userManagement.user.permissions.stockAdmin.get,
    userManagement.user.permissions.stockAdmin.update,
    userManagement.user.permissions.superManagerReadOnly.get,
    userManagement.user.permissions.superManagerReadOnly.update,
    userManagement.user.permissions.plants.get,
    userManagement.user.permissions.plants.update,
    userManagement.user.permissions.plannerGroups.get,
    userManagement.user.permissions.plannerGroups.update,
    userManagement.user.permissions.shipToPartyNumbers.get,
    userManagement.user.permissions.shipToPartyNumbers.update,
    userManagement.user.permissions.storageLocations.get,
    userManagement.user.permissions.storageLocations.update,
    userManagement.user.permissions.workCenters.get,
    userManagement.user.permissions.workCenters.update,
    userManagement.user.expirationDate.update,
    userManagement.user.permissions.createStockCount.get,
    userManagement.user.permissions.createStockCount.update,
    userManagement.user.permissions.addMaterialCount.get,
    userManagement.user.permissions.addMaterialCount.update,
    userManagement.user.permissions.deleteMaterialCount.get,
    userManagement.user.permissions.deleteMaterialCount.update,
    userManagement.user.permissions.editMaterialCountQuantity.get,
    userManagement.user.permissions.editMaterialCountQuantity.update,
    userManagement.user.permissions.submitStockCount.get,
    userManagement.user.permissions.submitStockCount.update,
    userManagement.user.permissions.cancelStockCount.get,
    userManagement.user.permissions.cancelStockCount.update,
    userManagement.user.permissions.editStockCount.get,
    userManagement.user.permissions.editStockCount.update,
    userManagement.user.permissions.shipToParty.get,
    userManagement.user.permissions.shipToParty.update,
    userManagement.user.permissions.requestStockTransfer.get,
    userManagement.user.permissions.requestStockTransfer.update
  ],
  super_manager_read_only: [
    stockManagement.get,
    userManagement.get,
    userManagement.allUsers.list,
    userManagement.user.permissions.get,
    userManagement.user.permissions.pmRead.get,
    userManagement.user.permissions.pmWrite.get,
    userManagement.user.permissions.pmUploadDisable.get,
    userManagement.user.permissions.pmUploadAdd.get,
    userManagement.user.permissions.umPmGrantWrite.get,
    userManagement.user.permissions.umPmGrantRead.get,
    userManagement.user.permissions.umUserAcceptance.get,
    userManagement.user.permissions.superManager.get,
    userManagement.user.permissions.mechanic.get,
    userManagement.user.permissions.customServiceAdmin.get,
    userManagement.user.permissions.supervisor.get,
    userManagement.user.permissions.stockAdmin.get,
    userManagement.user.permissions.superManagerReadOnly.get,
    userManagement.user.permissions.plants.get,
    userManagement.user.permissions.plannerGroups.get,
    userManagement.user.permissions.shipToPartyNumbers.get,
    userManagement.user.permissions.storageLocations.get,
    userManagement.user.permissions.workCenters.get,
    userManagement.user.permissions.requestStockTransfer.get
  ],
  mechanic: [
    stockManagement.get,
    userManagement.user.permissions.plants.have,
    userManagement.user.permissions.workCenters.have,
    userManagement.user.permissions.plannerGroups.have,
    userManagement.user.permissions.addMaterialCount.get,
    userManagement.user.permissions.addMaterialCount.update,
    userManagement.user.permissions.editMaterialCountQuantity.get,
    userManagement.user.permissions.editMaterialCountQuantity.update,
    userManagement.user.permissions.submitStockCount.get,
    userManagement.user.permissions.submitStockCount.update,
    userManagement.user.permissions.shipToParty.have
  ],
  custom_service_admin: [
    stockManagement.get,
    userManagement.user.permissions.plants.have,
    userManagement.user.permissions.storageLocations.get,
    userManagement.user.permissions.shipToParty.get
  ],
  supervisor: [
    stockManagement.get,
    userManagement.user.permissions.plants.have,
    userManagement.user.permissions.plannerGroups.have,
    userManagement.user.permissions.createStockCount.get,
    userManagement.user.permissions.createStockCount.update,
    userManagement.user.permissions.addMaterialCount.get,
    userManagement.user.permissions.addMaterialCount.update,
    userManagement.user.permissions.deleteMaterialCount.get,
    userManagement.user.permissions.deleteMaterialCount.update,
    userManagement.user.permissions.editMaterialCountQuantity.get,
    userManagement.user.permissions.editMaterialCountQuantity.update,
    userManagement.user.permissions.submitStockCount.get,
    userManagement.user.permissions.submitStockCount.update,
    userManagement.user.permissions.cancelStockCount.get,
    userManagement.user.permissions.cancelStockCount.update,
    userManagement.user.permissions.editStockCount.get,
    userManagement.user.permissions.editStockCount.update
  ],
  stock_admin: [
    stockManagement.get,
    userManagement.get,
    userManagement.myPlantUsers.list,
    userManagement.user.permissions.get,
    userManagement.user.permissions.update,
    userManagement.user.permissions.plants.have,
    userManagement.user.permissions.storageLocations.get,
    userManagement.user.permissions.storageLocations.update,
    userManagement.user.permissions.createStockCount.get,
    userManagement.user.permissions.createStockCount.update,
    userManagement.user.permissions.addMaterialCount.get,
    userManagement.user.permissions.addMaterialCount.update,
    userManagement.user.permissions.deleteMaterialCount.get,
    userManagement.user.permissions.deleteMaterialCount.update,
    userManagement.user.permissions.editMaterialCountQuantity.get,
    userManagement.user.permissions.editMaterialCountQuantity.update,
    userManagement.user.permissions.submitStockCount.get,
    userManagement.user.permissions.submitStockCount.update,
    userManagement.user.permissions.cancelStockCount.get,
    userManagement.user.permissions.cancelStockCount.update,
    userManagement.user.permissions.editStockCount.get,
    userManagement.user.permissions.editStockCount.update,
    userManagement.user.permissions.shipToParty.get,
    userManagement.user.permissions.shipToParty.update
  ]
}
deepFreeze(permittedActionsForPermission)

/**
 * Tells whether an action is permitted by one of given permissions.
 *
 * **Do not use inside views or components!**
 *
 * This function is only meant to be used by the store.
 *
 * @see ../store/index.js#default.getters.isPermitted
 */
export function isActionPermittedForPermissions (permittedAction, permissionIds) {
  return permissionIds.some(permission =>
    permittedActionsForPermission[permission]?.includes(permittedAction) ?? false
  )
}
