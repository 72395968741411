import Vue from 'vue'
import VueRouter from 'vue-router'
import LandingPage from '../views/LandingPage'
import store from '@/store'
import { messages } from '@/utils/strings'
import { NOTIFICATION_PAGE, STOCK_COUNT_PAGE } from '@/constants/route-names'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage,
    meta: {
      getTitle: messages.getPageLandingHeader
    }
  },
  {
    path: '/welcome',
    name: 'WelcomePage',
    component: () => import('../views/WelcomePage.vue'),
    meta: {
      getTitle: messages.getPageWelcomeHeader
    }
  },
  {
    path: '/status-monitor',
    name: 'StatusMonitor',
    component: () => import('../views/StatusMonitor.vue'),
    meta: {
      getTitle: messages.getPageStatusHeader
    }
  },
  {
    path: '/notifications',
    name: NOTIFICATION_PAGE,
    component: () => import('../views/NotificationsPage.vue'),
    meta: {
      getTitle: messages.getPageNotificationsHeader
    }
  },
  {
    path: '/stock-count',
    name: STOCK_COUNT_PAGE,
    component: () => import('../views/NotificationsPage.vue'),
    meta: {
      getTitle: messages.getPageNavigationsTabStockCount
    }
  },
  {
    path: '/mechanic-view/:id',
    name: 'MechanicView',
    component: () => import('../views/MechanicView.vue'),
    meta: {
      getTitle: messages.getPageMechanicViewHeader
    }
  },
  {
    path: '/first-login', // FIXME: Temporary redirect for missing FirstLogin route
    name: 'FirstLogin',
    redirect: { name: 'StatusMonitor' }
  },
  {
    path: '/priority-manager',
    name: 'PriorityManager',
    component: () => import('../views/PriorityManager.vue'),
    meta: {
      getTitle: messages.getPagePriorityHeader
    }
  },
  {
    path: '/stock-management',
    name: 'StockManagement',
    component: () => import('../views/StockManagement.vue'),
    meta: {
      getTitle: messages.getPageStockHeader
    }
  },
  {
    path: '/users-management',
    name: 'UsersManagement',
    component: () => import('../views/UsersManagement.vue'),
    meta: {
      getTitle: messages.getPageUsersHeader
    }
  },
  {
    path: '/user-profile',
    name: 'UserProfile',
    component: () => import('../views/UserProfile.vue'),
    meta: {
      getTitle: messages.getPageUserProfileHeader
    }
  },
  {
    path: '/info',
    name: 'HelpPage',
    component: () => import('../views/HelpPage.vue'),
    meta: {
      getTitle: messages.getPageInfoHeader
    }
  },
  {
    path: '/approval-request/:requestID',
    name: 'ApprovalRequest',
    component: () => import('../views/ApprovalRequest'),
    meta: {
      getTitle: messages.getPageApprovalRequestHeader
    }
  },
  {
    path: '/user-expired',
    name: 'UserExpired',
    component: () => import('../views/UserExpired.vue'),
    meta: {
      getTitle: messages.getPageUserExpiredHeader
    }
  },
  {
    path: '/order-details/:orderId',
    name: 'OrderDetails', // FIXME: OrderDetails also means /components/OrderDetails/OrderDetails.vue
    component: () => import('../views/OrderDetailsPage.vue'),
    props: true,
    meta: {
      getTitle: messages.getPageOrderDetailsHeader
    }
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/SearchStatusMonitor.vue'),
    meta: {
      getTitle: messages.getPageOrderDetailsHeader
    }
  },
  {
    path: '/stock-management-search',
    name: 'Search Stock Management',
    component: () => import('../views/SearchStockManagement.vue'),
    meta: {
      getTitle: messages.getPageSearchStockManagementHeader
    }
  },
  {
    path: '/material-count-search/:id',
    name: 'Search Stock Count',
    component: () => import('../views/SearchMaterialCount.vue'),
    meta: {
      getTitle: messages.getPageSearchMaterialCountHeader
    }
  },
  {
    path: '/mechanic-view/:id',
    name: 'MaterialCount',
    component: () => import('../views/MechanicView.vue'),
    props: true,
    meta: {
      getTitle: messages.getPageMaterialCountHeader
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if ( // Use normal flow if:
    store.state.profile.retrieved || // (1) user is ready
    !store.state.auth.idToken || // (2) user is waiting to be ready
    to.name === 'UserExpired' // (3) user has expired and is on expiration page
  ) {
    next()
  } else {
    store.dispatch('getProfile')
      .then((resp) => {
        store.dispatch('getConstants')
          .finally(() => {
            if (resp) {
              if (resp.name === to.name) {
                next()
              } else {
                next(resp)
              }
            } else if (to.name === 'LandingPage') {
              next({ name: 'StatusMonitor' })
            } else {
              next()
            }
          })
      })
  }
})

/**
 * Listen URL search query parameter change and apply it to Vuex store
 *
 * This will update the store `urlQuery` to match the current user free
 * text search.
 *
 * @param {Router} router - Vue router object
 * @param {Store} store - Vuex store
 */
function setUrlQueryListener (router, store) {
  // Disabled for now: See https://verkkoaps.atlassian.net/browse/CPL-379
  /* eslint-disable no-useless-return */
  return
  /*
  router.afterEach((to, from) => {
    // The first query means that it is the query parameter part in the
    // route object `to`. The second query means the actual parameter
    // eg. http://localhost:3000/status-monitor?query=000322741031&cursor=
    // see https://router.vuejs.org/api/#the-route-object for the route object.
    store.commit('setUrlQuery', to.query?.query)
  })
  */
}

export {
  router,
  setUrlQueryListener
}
