import {
  ALL_TYPE_OPTIONS
} from '@/constants/notifications'
import dropdown from '@/store/notifications/notifications-dropdown'
import settings from '@/store/notifications/notifications-settings'
import sms from '@/store/notifications/sms'
import email from '@/store/notifications/email'
import feedback from '@/store/notifications/feedback'
import pushNotification from '@/store/notifications/push-notification'

function validType (type) {
  const allowedTypes = ALL_TYPE_OPTIONS
  return allowedTypes.includes(type)
}

export default {
  namespaced: true,
  modules: {
    dropdown,
    settings,
    sms,
    email,
    feedback,
    pushNotification
  },
  state: {
    userSearchOptions: {
      type: null
    }
  },
  getters: {
    userSearchOptions: (state) => {
      return state.userSearchOptions
    }
  },
  mutations: {
    setSearchOptionType (state, type) {
      if (!validType(type)) {
        throw Error(`Invalid value ${type} for search option "type"`)
      }
      state.userSearchOptions = {
        ...state.userSearchOptions,
        type
      }
    }
  }
}
