import axios from 'axios'
const BASE_URL = process.env.VUE_APP_USER_API_ENDPOINT

export function getNotificationSettingsApi (userId) {
  const endpoint = `/user_api/v1/users/${userId}/notifications_settings`
  const url = BASE_URL + endpoint
  const requestPromise = axios.get(url)
    .catch((error) => {
      console.error({ _: 'Get Notification Settings failed', url, error })
      throw error
    })
  return requestPromise
}
export function putNotificationSettingsApi (userId, notificationSettings) {
  const endpoint = `/user_api/v1/users/${userId}/notifications_settings`
  const url = BASE_URL + endpoint
  const requestPromise = axios.put(url, { notificationSettings })
    .catch((error) => {
      console.error({ _: 'Put Notification Settings failed', url, error })
      throw error
    }
    )
  return requestPromise
}
export function getNotificationDropDownListApi () {
  const endpoint = '/user_api/v1/notifications'
  const url = BASE_URL + endpoint
  const requestPromise = axios.get(url)
    .catch((error) => {
      console.error({ _: 'Get Notifications DropDown List failed', url, error })
      throw error
    })
  return requestPromise
}

export function putSmsNotificationMarkAsReadApi (id) {
  const endpoint = `/user_api/v1/sms_notifications/${id}`
  const requestBody = {
    active: false
  }
  const url = BASE_URL + endpoint
  const requestPromise = axios.put(url, requestBody)
    .catch((error) => {
      console.error({ _: 'Put SMS Notification Mark As Read failed', url, error })
      throw error
    }
    )
  return requestPromise
}

export function putEmailNotificationMarkAsReadApi (id) {
  const endpoint = `/user_api/v1/email_notifications/${id}`
  const requestBody = {
    active: false
  }
  const url = BASE_URL + endpoint
  const requestPromise = axios.put(url, requestBody)
    .catch((error) => {
      console.error({ _: 'Put Email Notification Mark As Read failed', url, error })
      throw error
    }
    )
  return requestPromise
}

export function putPushNotificationMarkAsReadApi (id) {
  const endpoint = `/user_api/v1/push_notifications/${id}`
  const requestBody = {
    active: false
  }
  const url = BASE_URL + endpoint
  const requestPromise = axios.put(url, requestBody)
    .catch((error) => {
      console.error({ _: 'Put Push Notification Mark As Read failed', url, error })
      throw error
    }
    )
  return requestPromise
}

export function getUAPsApi () {
  const url = BASE_URL + '/user_api/v1/uap_priority'
  const requestPromise = axios.get(url)
    .catch((error) => {
      console.error({ _: 'Get UAP failed', url, error })
      throw error
    })
  return requestPromise
}
