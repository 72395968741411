<template>
  <div
    class="text-center h-100 d-flex justify-content-center align-items-center"
  >
    <div>
      <p>
        <slot>{{ messages.getLabelGettingUserInfo() }}</slot>
      </p>
      <b-spinner
        class="spinner"
        :label="messages.getLabelLoading()"
      />
    </div>
  </div>
</template>

<script>
import { messages } from '@/utils/strings'

export default {
  name: 'LoadingComponent',
  data () {
    return {
      messages
    }
  }
}
</script>

<style scoped>
.spinner {
  width: 3rem;
  height: 3rem;
}
.text-center {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
}
</style>
