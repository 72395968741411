export function renderMessageWithLinks (message) {
  if (!message) return ''
  // Regular expression to find URLs in the message or https:// or http://
  const urlRegex = /(https?:\/\/[^\s)]+)/g
  const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/g
  // Replace emails with clickable links
  message = message.replace(emailRegex, '<a href="mailto:$1">$1</a>')
  // Replace URLs with clickable links
  return message.replace(urlRegex, '<a href="$1" target="_blank">$1</a>')
}

export function removeSpecialCharacterPhoneNumbers (phoneNumber) {
  return phoneNumber.replace(/[^0-9]/g, '')
}
