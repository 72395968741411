import {
  GT, LT, GTE, LTE, EQ, NE,
  GT_EQUIVALENT, LT_EQUIVALENT, GTE_EQUIVALENT, LTE_EQUIVALENT, EQ_EQUIVALENT, NE_EQUIVALENT
} from '@/constants/shared-constants'

const delimiters = [GTE, LTE, GT, LT, NE, EQ]
const delimiterEquivalents = [GTE_EQUIVALENT, LTE_EQUIVALENT, GT_EQUIVALENT, LT_EQUIVALENT, NE_EQUIVALENT, EQ_EQUIVALENT]

export function searchInput (items, validator = (x) => true, mapper = (x) => x) {
  return {
    items,
    validator,
    mapper
  }
}
export function formatArraySearchString (allowedSearchKeys, searchArray) {
  /**
   * Format search string for search API.
   * if array doesn't contain any of the (delimiters) then add 'search= to that element
   * if array contains any of the (delimiters) then take the string before the (delimiter) and match it with keywordDict and replace it with the value of the key
   * remove all spaces between the (delimiter) sign, dont add '&' to the last element
   * search element if it in the value of the key in keywordDict then replace it with the key
   * @param {array[searchInput]} allowedSearchKeys - The allowed search keys (searchInputs). e.g. { plant: {
   *                                                                               validator: validatePlant,
   *                                                                               mapper: mapPlant,
   *                                                                               items: ['plants','plant']
   *                                                                             }
   *                                                                    }
   * @param {array[string]} searchArray - The search array. e.g. ['plant=plant1',''plant=plant2'', 'work center=work center 1', '123','test']
   * @returns {string, array} A formatted search string and an array of error indexes. eg. { searchString: 'plant=plant1,plant2&work_center=work center 1&search=123,test', errorIndexes: [] }
   */

  let searchString = ''
  const errorIndexes = []
  const searchDict = {}
  searchArray.forEach((element) => {
    const delimiter = delimiters.find((delimiter) => element.includes(delimiter))
    if (delimiter) {
      const delimiterEquivalent = delimiterEquivalents[delimiters.indexOf(delimiter)]
      const splitElement = element.split(delimiter)
      const keys = getKeysByValue(allowedSearchKeys, splitElement[0].trim().toLowerCase())
      let value = splitElement[1].trim()
      if (keys.length === 0 || value === '') {
        errorIndexes.push(searchArray.indexOf(element))
      } else {
        keys.forEach((key) => {
          const validator = allowedSearchKeys[key].validator
          const mapper = allowedSearchKeys[key].mapper
          if (validator(value)) {
            value = mapper(value)
            // if the key is already in the searchDict then add the value to the string with ','
            if (searchDict[key]) {
              searchDict[key] += `,${value}`
            } else {
              searchDict[key] = `${delimiterEquivalent}${value}`
            }
          } else {
            errorIndexes.push(searchArray.indexOf(element))
          }
        })
      }
    } else {
      if (!searchDict.search) {
        searchDict.search = `=${element}`
      } else {
        searchDict.search += `,${element}`
      }
    }
  })
  // add the searchDict to the string
  Object.entries(searchDict).forEach(([key, value]) => {
    searchString += `${key}${value}&`
  })
  // remove the last '&' from the string
  searchString = searchString.slice(0, -1)
  return { searchDict, searchString, errorIndexes }
}

function getKeysByValue (object, value) {
/**
 * Get the keys from an object by its value, where the value of the object is an array.
 * @param {object} object - The object to search in.needs to be in { items: ['plants','plant'] } format
 * @param {string} value - The value to search for.
 * @returns {string} The keys of the value in an array.
 */
  return Object.keys(object).filter(key => {
    return object[key].items.includes(value)
  })
}
