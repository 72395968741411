import axios from 'axios'
import { MY_MATERIAL, PLANT, STORAGE_LOCATION, MECHANIC_STORAGE_LOCATION } from '@/constants/stock-count'
const BASE_URL = process.env.VUE_APP_STOCK_API_ENDPOINT

export function getMaterialCodesApi (queryParameters) {
  const DEFAULT_PARAMETERS = 'maxResults=20&page=1'
  const { searchValue } = queryParameters
  let url = BASE_URL + '/api/v2/'
  url += `${MY_MATERIAL}?${DEFAULT_PARAMETERS}&materialCode=${searchValue}`
  const requestPromise = axios.get(url)
    .catch(error => {
      console.error({ _: `list ${MY_MATERIAL}s failed`, queryParameters, url, error })
      throw error
    })
  return requestPromise
}

export function getStorageLocationsByPlantApi (queryParameters) {
  const url = BASE_URL + `/api/v2/relationship/${STORAGE_LOCATION}?${PLANT}=${queryParameters.plant}`
  const requestPromise = axios.get(url)
    .catch(error => {
      console.error({ _: `list ${STORAGE_LOCATION}s failed`, queryParameters, url, error })
      throw error
    })
  return requestPromise
}
export function getStorageLocationsByPlantNoRestrictionApi (queryParameters) {
  const url = BASE_URL + `/api/v2/relationship/${MECHANIC_STORAGE_LOCATION}?${PLANT}=${queryParameters.plant}`
  const requestPromise = axios.get(url)
    .catch(error => {
      console.error({ _: `list ${MECHANIC_STORAGE_LOCATION}s failed`, queryParameters, url, error })
      throw error
    })
  return requestPromise
}

export function getPlantsApi () {
  const url = BASE_URL + `/api/v2/relationship/${PLANT}`
  const requestPromise = axios.get(url)
    .catch((error) => {
      console.error({ _: 'Get Plants failed', url, error })
      throw error
    })
  return requestPromise
}

export function getStorageLocationsRelationshipsByPlantByWorkCenterApi (queryParameters) {
  // This function is used to get the storage locations relationship by plant and work center
  // currently mechanic work center is used to get the relationship given a plant (you do not need to pass the work center it used from the profile at the backend)
  // for other users if work center is not passed it will return all the storage locations for the plant
  let url = BASE_URL + `/api/v2/relationship/all_stock_visibility?${PLANT}=${queryParameters.plant}`
  if (queryParameters.workCenter) {
    url += `&${queryParameters.workCenter}`
  }
  const requestPromise = axios.get(url)
    .catch(error => {
      console.error({ _: `list ${STORAGE_LOCATION}s failed`, queryParameters, url, error })
      throw error
    })
  return requestPromise
}
