<template>
  <div>
    <loading-spinner-cover />
    <slot name="topBar"></slot>
    <b-container
      fluid
      class="px-lg-5 h-100"
    >
      <b-row
        class="h-100"
      >
        <slot></slot>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import LoadingSpinnerCover from '@/components/LoadingSpinnerCover'

export default {
  name: 'PageLayout',
  components: { LoadingSpinnerCover }
}
</script>

<style scoped>

</style>
