import * as systemInfo from '@/constants/system-info.js'

const nAgt = navigator.userAgent
let OSName = systemInfo.UNKNOWN
let browserName = systemInfo.UNKNOWN
export function getSystemInfo () {
  if (nAgt.indexOf('Win') !== -1) OSName = systemInfo.WINDOWS
  if (nAgt.indexOf('Mac') !== -1) OSName = systemInfo.MACINTOSH
  if (nAgt.indexOf('Linux') !== -1) OSName = systemInfo.LINUX
  if (nAgt.indexOf('Android') !== -1) OSName = systemInfo.ANDROID
  if (nAgt.indexOf('like Mac') !== -1) OSName = systemInfo.IOS
  if ((nAgt.indexOf('Opera') || nAgt.indexOf('OPR')) !== -1) {
    browserName = systemInfo.OPERA
  } else if (nAgt.indexOf('Edg') !== -1) {
    browserName = systemInfo.EDGE
  } else if (nAgt.indexOf('Chrome') !== -1) {
    browserName = systemInfo.CHROME
  } else if (nAgt.indexOf('Safari') !== -1) {
    browserName = systemInfo.SAFARI
  } else if (nAgt.indexOf('Firefox') !== -1) {
    browserName = systemInfo.FIREFOX
  } else if (nAgt.indexOf('MSIE') !== -1 || !!document.documentMode === true) {
    // IF IE > 10
    browserName = systemInfo.IE
  }
  return {
    OSName,
    browserName
  }
}
