// Permissions used when checking if the user poses a given permission by name.
// All permissions together with their labels are accessible in the store.constants.permissions
// Note $store.profile.permissions

export const PM_READ = 'PM_read'
export const PM_WRITE = 'PM_write'
export const PM_UPLOAD_ADD = 'PM_upload_add'
export const PM_UPLOAD_DISABLE = 'PM_upload_disable'

export const UM_USER_ACCEPTANCE = 'UM_user_acceptance'
export const UM_PM_GRANT_READ = 'UM_PM_grant_read'
export const UM_PM_GRANT_WRITE = 'UM_PM_grant_write'

export const SUPER_MANAGER = 'super_manager'
// Super manager read only can see all tracking_statuses and priority manager items
// but is not allowed to change anything. Because of that users tab is not valid
// and profile important message is also unvalid.
export const SUPER_MANAGER_READ_ONLY = 'super_manager_read_only'

export const MECHANIC = 'mechanic'
export const CUSTOM_SERVICE_ADMIN = 'custom_service_admin'
export const SUPERVISOR = 'supervisor'
export const STOCK_ADMIN = 'stock_admin'
export const KONE_ROLES = [MECHANIC, CUSTOM_SERVICE_ADMIN, SUPERVISOR]

export const CAMEL_CASED_PERMISSION_NAMES = {
  PM_read: 'pmRead',
  PM_write: 'pmWrite',
  PM_upload_add: 'pmUploadAdd',
  PM_upload_disable: 'pmUploadDisable',
  UM_user_acceptance: 'umUserAcceptance',
  UM_PM_grant_read: 'umPmGrantRead',
  UM_PM_grant_write: 'umPmGrantWrite',
  super_manager: 'superManager',
  super_manager_read_only: 'superManagerReadOnly',
  mechanic: 'mechanic',
  custom_service_admin: 'customServiceAdmin',
  supervisor: 'supervisor',
  stock_admin: 'stockAdmin'
}
Object.freeze(CAMEL_CASED_PERMISSION_NAMES)
