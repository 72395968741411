<template>
  <div
    class="view-wrapper h-100"
    :style="{ 'margin-left': leftMargin, 'margin-top': topMargin, 'background-color': backgroundColor }"
    :class="{ 'm-0': !showNavigation }"
  >
    <slot
      :set-background-color="setBackgroundColor"
    />
    <div
      class="bottom-bar-spacer"
      :style="{ 'height': bottomBarHeight }"
    />
  </div>
</template>

<script>
export default {
  name: 'ViewWrapper',
  props: {
    leftMargin: {
      required: true,
      type: String
    },
    bottomBarHeight: {
      required: true,
      type: String
    },
    showNavigation: {
      required: true,
      type: Boolean
    },
    topMargin: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      backgroundColor: ''
    }
  },
  methods: {
    setBackgroundColor (color) {
      this.backgroundColor = color
    }
  },
  watch: {
    $route: function () {
      this.backgroundColor = ''
    }
  }
}
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@include media-breakpoint-down(md) {
  .view-wrapper {
    margin-left: 0!important;
    margin-top: 0!important;
  }
}
@include media-breakpoint-up(lg) {
  .bottom-bar-spacer {
    height: 0!important;
  }
}
</style>
