<template>
  <b-navbar
    fixed="top"
    class="top-bar bg-light"
    :style="{ 'margin-left': leftMargin, 'height': height }"
  >
    <b-navbar-brand>
      <div
        class="kone-parts-tracker text-uppercase"
      >
        {{ messages.getPageTitle() }}
      </div>
      <div
        class="text-uppercase title"
      >
        {{ title }}
      </div>
    </b-navbar-brand>
    <b-navbar-nav>
      <important-message-alert class="d-flex"/>
      <b-nav-item class="d-flex align-items-center">
    <!-- isMobile is used here to prevent modals from rendering twice -->
       <NotificationDropDown v-if="!isMobile() && inAllowedNotificationPlants"/>
      </b-nav-item>
      <b-nav-item
        class="d-flex align-items-center"
      >
        <b-avatar
          :text="avatarInitials"
          class="margin-right bg-primary text-white"
          size="2em"
        />
      </b-nav-item>
      <b-nav-item>
        <i
          class="icon-kone-logo text-primary"
        />
      </b-nav-item>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import { messages } from '@/utils/strings'
import NotificationDropDown from '@/components/Notifications/NotificationDropDown.vue'
import ImportantMessageAlert from '../ImportantMessage/ImportantMessageAlert.vue'
import { MOBILE_WIDTH } from '@/constants/screen'
import { mapGetters } from 'vuex'
import { NOTIFICATION_SETTINGS } from '@/constants/store-names'
export default {
  name: 'TopBar',
  components: {
    NotificationDropDown,
    ImportantMessageAlert
  },
  props: {
    leftMargin: {
      required: true,
      type: String
    },
    height: {
      required: true,
      type: String
    }
  },
  // components: { BIconBell, BIconCircleFill, BIconstack },
  data () {
    return {
      role: 'ADMIN',
      messages
    }
  },
  methods: {
    navigateTo (path) {
      this.$router.push(path) // Use Vue Router to navigate
    },
    isMobile () {
      return this.$screen.width <= MOBILE_WIDTH
    }
  },
  computed: {
    ...mapGetters({
      inAllowedNotificationPlants: `${NOTIFICATION_SETTINGS}/inAllowedNotificationPlants`
    }),
    title () {
      return this.$route.meta.getTitle?.()
    },
    avatarInitials () {
      const displayName = this.$store.state.displayName
      const names = displayName.split(' ')
      // add first letter of every name to initials and return them
      return names.reduce(
        (initials, name) => initials + name.split('', 1),
        ''
      )
    }
  }
}
</script>

<style scoped lang="scss">
.top-bar {
  display: flex;
  justify-content: space-between;
}
.kone-parts-tracker {
  font-size: 16px;
  color: $gray-400;
}
.title {
  font-size: 40px;
}
.icon-kone-logo {
  font-size: 65px;
}
.icon-alarm {

  font-size: 30px;

}
.icon-chevron-down {
  width: 16px;
}
.user {
  font-size: 14px;
  color: $gray-900;
}
.margin-right {
  margin-right: 8px;
}
</style>
