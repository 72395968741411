import moment from 'moment'
import createPaginationModule from '../generic/pagination'
import createSingleItemCrudModule from '../generic/single-item-crud'
import { getPlantsApi, getMaterialCodesApi, getStorageLocationsRelationshipsByPlantByWorkCenterApi, getStorageLocationsByPlantNoRestrictionApi } from './cp-stock-management-apis'
import { getUAPsApi } from './logistics-apis'
import {
  FEEDBACK,
  FEEDBACK_TYPE_OPTIONS
} from '@/constants/notifications'
import { SUPER_MANAGER, SUPER_MANAGER_READ_ONLY } from '@/constants/permissions'
import { hasCommonElement } from '@/utils/helper_functions'
import { CREATE } from '@/constants/store-actions'
const BASE_URL = process.env.VUE_APP_USER_API_ENDPOINT
const CREATE_FEEDBACK_URL = `${BASE_URL}/user_api/v1/feedback`

function validType (type) {
  const allowedTypes = FEEDBACK_TYPE_OPTIONS
  return allowedTypes.includes(type)
}
function parseFeedbackItems (item) {
  return {
    ...item,
    created: moment(item.created).format('lll')
  }
}

export default {
  namespaced: true,
  modules: {
    feedbackPagination: createPaginationModule({
      endpoint: CREATE_FEEDBACK_URL,
      mapper: parseFeedbackItems
    }),
    ratingFeedbackForm: {
      ...createSingleItemCrudModule({
        endpoint: CREATE_FEEDBACK_URL,
        actions: [CREATE]
      }),
      namespaced: true
    },
    reportIssue: {
      ...createSingleItemCrudModule({
        endpoint: CREATE_FEEDBACK_URL,
        actions: [CREATE]
      }),
      namespaced: true
    },
    requestUpdateOrder: {
      ...createSingleItemCrudModule({
        endpoint: CREATE_FEEDBACK_URL,
        actions: [CREATE]
      }),
      namespaced: true
    },
    requestStockTransfer: {
      ...createSingleItemCrudModule({
        endpoint: CREATE_FEEDBACK_URL,
        actions: [CREATE]
      }),
      namespaced: true
    },
    reportStockDifference: {
      ...createSingleItemCrudModule({
        endpoint: CREATE_FEEDBACK_URL,
        actions: [CREATE]
      }),
      namespaced: true
    },
    requestUpdateAccessPointContactNotification: {
      ...createSingleItemCrudModule({
        endpoint: CREATE_FEEDBACK_URL,
        actions: [CREATE]
      }),
      namespaced: true
    }
  },
  state: {
    selectedReportForm: null,
    lastSelectedReportForm: FEEDBACK,
    plants: [],
    materialCodes: [],
    storageLocations: [],
    toStorageLocations: [],
    UAPs: []
  },
  getters: {
    getSelectedReportForm: (state) => {
      return state.selectedReportForm
    },
    getLastSelectedReportForm: (state) => {
      return state.lastSelectedReportForm
    },
    getPlants: (state) => {
      return state.plants
    },
    getMaterialCodes: (state) => {
      return state.materialCodes
    },
    profileStorageLocations: (state, getters, rootState) => {
      return rootState.profile.storageLocations
    },
    getStorageLocations: (state, getters, rootState, rootGetters) => {
      return state.storageLocations
    },
    getToStorageLocations: (state) => {
      return state.toStorageLocations
    },
    getUAPs: (state) => {
      return state.UAPs
    }
  },
  mutations: {
    async setSelectedReportForm (state, type) {
      if (!validType(type) && type !== null) {
        throw Error(`Invalid value ${type} for search option "type"`)
      }
      state.selectedReportForm = type
      if (type !== null) state.lastSelectedReportForm = type
    },
    async setPlants (state, plants) {
      state.plants = plants
    },
    async setMaterialCodes (state, materialCodes) {
      state.materialCodes = materialCodes
    },
    async setStorageLocations (state, storageLocations) {
      state.storageLocations = storageLocations
    },
    async setToStorageLocations (state, toStorageLocations) {
      state.toStorageLocations = toStorageLocations
    },
    async setUAPs (state, UAPs) {
      state.UAPs = UAPs
    }
  },
  actions: {
    async fetchPlants ({ commit, rootState }) {
      const userPermissions = rootState.profile.permissions
      if (hasCommonElement(userPermissions, [SUPER_MANAGER, SUPER_MANAGER_READ_ONLY])) {
        getPlantsApi()
          .then((response) => {
            commit('setPlants', response.data.plants)
            return response
          })
          .catch((error) => {
            console.error({ _: 'Get Plants failed', error })
          })
      } else {
        commit('setPlants', rootState.profile.plants)
      }
    },
    async fetchMaterialCodes ({ commit }, queryParameters) {
      const requestPromise = getMaterialCodesApi(queryParameters)
        .then((response) => {
          commit('setMaterialCodes', response.data.my_material_items.map(materialCode => ({
            name: materialCode.material_code,
            value: materialCode.material_code
          }))
          )
          return response
        })
        .catch((error) => {
          console.error({ _: 'Get Material Codes failed', error })
        })
      return requestPromise
    },
    async fetchStorageLocations ({ commit }, queryParameters) {
      const requestPromise = getStorageLocationsRelationshipsByPlantByWorkCenterApi(queryParameters)
        .then((response) => {
          commit('setStorageLocations', [...new Set(response.data.relationships.filter(relationship => relationship.storage_location).flatMap(relationship => relationship.storage_location))]
          )
          return response
        })
        .catch((error) => {
          console.error({ _: 'Get Storage Locations failed', error })
        })
      return requestPromise
    },
    async fetchStorageLocationsNoRestriction ({ commit }, queryParameters) {
      const requestPromise = getStorageLocationsByPlantNoRestrictionApi(queryParameters)
        .then((response) => {
          commit('setToStorageLocations', response.data.storage_locations
          )
          return response
        })
        .catch((error) => {
          console.error({ _: 'Get Storage Locations failed', error })
        })
      return requestPromise
    },
    async toggleSelectedReportForm ({ commit, state }) {
      if (state.selectedReportForm === null) {
        commit('setSelectedReportForm', state.lastSelectedReportForm)
      } else {
        commit('setSelectedReportForm', null)
      }
    },
    async fetchUAPs ({ commit }) {
      getUAPsApi()
        .then((response) => {
          commit('setUAPs', response.data.uap_ids)
          return response
        })
        .catch((error) => {
          console.error({ _: 'Get Plants failed', error })
        })
    }
  }
}
