/**
 * Getters for language- and environment-aware strings and messages.
 */

import moment from 'moment'
import store from '@/store'
import escapeReplacementString from '@/utils/escape-replacement-string'
import { ensureValidLocale } from '@/constants/locales'
import DE_DE from '@/localizations/de-DE.json'
import EN_US from '@/localizations/en-US.json'
import ES_ES from '@/localizations/es-ES.json'
import FI_FI from '@/localizations/fi-FI.json'
import FR_FR from '@/localizations/fr-FR.json'
import IT_IT from '@/localizations/it-IT.json'
import SV_SE from '@/localizations/sv-SE.json'
import CS_CZ from '@/localizations/cs-CZ.json'
import PT_PT from '@/localizations/pt-PT.json'
import { AT_ACCESS_POINT, DELIVERED, ON_THE_WAY } from '@/constants/shared-constants'
import { APPROVED, REJECTED, PENDING } from '@/constants/approval_status'

const languageStringsByCode = {
  'cs-CZ': CS_CZ,
  'de-DE': DE_DE,
  'en-US': EN_US,
  'es-ES': ES_ES,
  'fi-FI': FI_FI,
  'fr-FR': FR_FR,
  'it-IT': IT_IT,
  'pt-PT': PT_PT,
  'sv-SE': SV_SE
}

/**
 * To show something to user in cases where a string in the language file is missing.
 * @param {string} fallbackString
 * @return {string} fallbackString formatted so that it is recognized as a fallback (e.g. prefixed with "no translation")
 */
function languageFallback (fallbackString) {
  // I frequently switch on the "no translation" tagged version to spot missing strings, that's why it has been left here.
  // return `(no translation) ${ fallbackString }`
  return `${fallbackString}`
}

/**
 * Getter for current language code.
 * @return {string} for example "en-US"
 */
function getCurrentLanguageCode () {
  return ensureValidLocale(store.state.profile.locale)
}

/**
 * Getter for collection of strings in given language.
 * @param {string} languageCode, example "en-US"
 * @return {(object|null)}
 */
function getLanguageStrings (languageCode) {
  return languageStringsByCode[languageCode] ?? null
}

/**
* Getter for current language strings. Needed in all language-aware string
* getters (see messages and builtStrings).
* @return {object}
*/
function getCurrentLanguageStrings () {
  const languageCode = getCurrentLanguageCode()

  const languageStrings = getLanguageStrings(languageCode)

  if (!languageStrings) {
    // Existing language code but nonexistent language file should not happen.
    // They are set together at load time, so something is badly wrong now.

    throw new Error(
      `No language found with code ${languageCode
      }, although it has been set as current.`
    )
  }

  return languageStrings
}

export const messages = {
  /**
   * Returns the string for the required field message.
   * @param {string} field
   * @returns {string}
   */
  getRequiredFieldMessage (field) {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.messages?.requiredField || languageFallback('Field <field> is required')
    return message.replace('<field>', escapeReplacementString(field))
  },
  /**
   * Return the string of I want to have a copy of the e-mail
   * @return {string}
   */
  getFieldWantCopyEmail () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.wantCopyEmail || languageFallback('I want to have a copy of the e-mail')
    return message
  },
  /**
   * Return submit button text
   * @return {string}
   */
  getSubmitButtonText () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.submitButtonText || languageFallback('Submit')
    return message
  },
  /**
   * Return the string of Description
   * @return {string}
   */
  getFieldDescription () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.description || languageFallback('Description')
    return message
  },
  /**
   * Return the string of comment
   * @return {string}
   */
  getFieldComment () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.comment || languageFallback('Comment')
    return message
  },
  /**
   * Return the string of comment
  * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   */
  getFieldPrioUAP (priority) {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.uap || languageFallback('Priority 1 UAP')
    return message.replace('1', priority)
  },
  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionAdd () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.add || languageFallback('Add')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionAddToSearch () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.addToSearch || languageFallback('Add to search')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionApprove () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.approve || languageFallback('Approve')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionCheckExpirationAgain () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.checkExpirationAgain || languageFallback('Check expiration again')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionClear () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.clear || languageFallback('Clear')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionClose () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.close || languageFallback('Close')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionDelete () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.delete || languageFallback('Delete')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionDeleteBroadcastMessage () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.deleteBroadcastMessage || languageFallback('Delete message')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionExpandIcon () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.expandIcon || languageFallback('Expand icon')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionFeedback () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.feedback || languageFallback('Feedback')
    return message
  },
  /**
   * Returns the string for Report Issue button.
   * @return {string}
   */
  getActionReportIssue () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.reportIssue || languageFallback('Report Issue')
    return message
  },
  /**
    * Returns the string for Report Issue button.
    * @return {string}
    */
  getActionRequestUpdateOrder () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.requestUpdateOrder || languageFallback('Request Update ROP or ROQ')
    return message
  },
  /**
   * Returns the string for Report Request Stock Transfer button.
   * @return {string}
  */
  getActionRequestStockTransfer () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.requestStockTransfer || languageFallback('Request Stock Transfer')
    return message
  },
  /**
   * Returns the string for Report Stock Difference button.
   * @return {string}
  */
  getActionReportStockDifference () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.reportStockDifference || languageFallback('Report Stock Difference')
    return message
  },
  /**
   * Returns the string for Report Stock Difference button.
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
  */
  getActionUpdateAccessPointContactNotification () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.requestUpdateOnAccessPointSelection || languageFallback('Request Update On Access Point Selection')
    return message
  },
  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionGoBack () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.goBack || languageFallback('Go back')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionGoToRequest () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.goToRequest || languageFallback('GO TO REQUEST')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionLoadMore () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.loadMore || languageFallback('Load more')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionNextPage () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.nextPage || languageFallback('NEXT PAGE')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionOnlyShowMyOrders () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.onlyShowMyOrders || languageFallback('Only display my orders')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionPreviousPage () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.previousPage || languageFallback('PREVIOUS PAGE')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionReject () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.reject || languageFallback('Reject')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   */
  getActionRemove () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.remove || languageFallback('Remove')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionRemoveUser () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.removeUser || languageFallback('Remove User')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionResetSearch () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.resetSearch || languageFallback('Reset search')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionSaveBroadcastMessage () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.saveBroadcastMessage || languageFallback('Save message')
    return message
  },
  /**
   * Save button for important messages in user profile.
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   */
  getActionSaveBroadcastMessages () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.saveBroadcastMessages || languageFallback('Save messages')
    return message
  },
  /**
   * Returns the string for the action to save notification preferences.
   * @returns {string}
   */
  getActionSaveNotificationPreferences () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.saveNotificationPreferences || languageFallback('Save preferences')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionSaveExpirationDate () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.saveExpirationDate || languageFallback('Save expiration date')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionSelectFile () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.selectFile || languageFallback('Select File')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionSetBottom () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.setBottom || languageFallback('Set Bottom')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionSetSecond () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.setSecond || languageFallback('Set Second')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionSetTop () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.setTop || languageFallback('Set Top')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionSave () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.save || languageFallback('Save')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionSendApprovalRequest () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.sendApprovalRequest || languageFallback('SEND APPROVAL REQUEST')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionSignOut () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.signOut || languageFallback('SIGN OUT')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionUpload () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.upload || languageFallback('Upload')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionUploadToAddPriorities () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.uploadToAddPriorities || languageFallback('UPLOAD TO ADD')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionUploadToDeletePriorities () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.uploadToDeletePriorities || languageFallback('UPLOAD TO DELETE')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getActionView () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.view || languageFallback('View')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldAccessPointAddress () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.accessPointAddress || languageFallback('UAP Address')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldAvailablePermissions () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.availablePermissions || languageFallback('Available permissions to set or unset')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldBin () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.bin || languageFallback('BIN')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldBlocks () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.blocks || languageFallback('Blocks')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldConfirmation () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.confirmation || languageFallback('Confirmation')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldDelivery () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.delivery || languageFallback('Delivery')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldDeliveryDate () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.deliveryDate || languageFallback('Delivery date')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldDestination () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.destination || languageFallback('Destination')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldDetails () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.details || languageFallback('Details')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldEta () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.eta || languageFallback('ETA')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldExpirationDate () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.expirationDate || languageFallback('Expiration date')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldInspection () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.inspection || languageFallback('Inspection')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldLanguage () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.language || languageFallback('Language')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldLastLogin () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.lastLogin || languageFallback('Last login')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldMaterialCode () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.materialCode || languageFallback('Material Code')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldMaterialDescription () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.materialDescription || languageFallback('Material Description')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldPermissions () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.permissions || languageFallback('Permissions')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldPc () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.pc || languageFallback('PC')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldPlannerGroups () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.plannerGroups || languageFallback('Planner Groups')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldPlant () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.plant || languageFallback('Plant')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldPlants () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.plants || languageFallback('Plants')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldPriority () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.priority || languageFallback('Priority')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldPurchaseOrder () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.purchaseOrder || languageFallback('Purchase order')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldQuantity () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.quantity || languageFallback('Quantity')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldReorderPoint () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.reorderPoint || languageFallback('ReOrderPoint')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldReorderQuantity () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.reorderQuantity || languageFallback('ReOrderQuantity')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldRequired () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.required || languageFallback('Required')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldRestricted () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.restricted || languageFallback('Restricted')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldRop () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.rop || languageFallback('ROP')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldRoq () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.roq || languageFallback('ROQ')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldServiceOrder () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.serviceOrder || languageFallback('Service order')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldShipToPartyNumber () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.shipToPartyNumber || languageFallback('Ship to Party Number')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldStorageLocation () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.storageLocation || languageFallback('Storage Location')
    return message
  },
  /**
   * This function returns the label for the field storage location that is used as an input for transfer from.
   * @return {string}
   * */
  getFieldFromStorageLocation () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.storageLocationFrom || languageFallback('From Storage Location')
    return message
  },
  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldStorageLocations () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.storageLocations || languageFallback('Storage Locations')
    return message
  },
  /**
   * This function returns the label for the field storage location that is used as an input.
   * @return {string}
   */
  getFieldStorageLocationToMove () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.storageLocationMove || languageFallback('To Storage Location')
    return message
  },
  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldQuantityToMove () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.materialQuantityMove || languageFallback('Material Quantity')
    return message
  },
  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldTransfer () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.transfer || languageFallback('Transfer')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldUnrestricted () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.unrestricted || languageFallback('Unrestricted')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getFieldWorkCenters () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.workCenters || languageFallback('Work Centers')
    return message
  },

  /**
   * This function returns the label Notifications Preferences
   * @returns {string}
   */
  getLabelNotificationsPreferences () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.notificationsPreferences || languageFallback('Notifications preferences')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelAddPlannerGroup () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.addPlannerGroup || languageFallback('Add planner group')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelAddPlant () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.addPlant || languageFallback('Add plant')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelAllItemsShown () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.allItemsShown || languageFallback('All items shown')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelApprovalPending ({ email }) {
    const currentLanguage = getCurrentLanguageStrings()
    let message = currentLanguage?.label?.approvalPending || languageFallback('Your approval request sent to askit@kone.com is pending')
    message = message.replace('askit@kone.com', escapeReplacementString(email))
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelApprovalRejected ({ email }) {
    const currentLanguage = getCurrentLanguageStrings()
    let message = currentLanguage?.label?.approvalRejected || languageFallback('Your approval request sent to askit@kone.com is rejected')
    message = message.replace('askit@kone.com', escapeReplacementString(email))
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelApprovalRequestDecision () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.approvalRequestDecision || languageFallback('Please choose one option')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelApprovalRequestDescription ({ email }) {
    const currentLanguage = getCurrentLanguageStrings()
    let message = currentLanguage?.label?.approvalRequestDescription || languageFallback('This approval request was created by <request-user@example.com> and waiting for decision.')
    message = message.replace('<request-user@example.com>', escapeReplacementString(email))
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelApprovalRequestStatus ({ email, requestStatus }) {
    switch (requestStatus) {
      case APPROVED: return this.getLabelUserApproved({ email })
      case PENDING: return this.getLabelApprovalRequestDescription({ email })
      case REJECTED: return this.getLabelUserRejected({ email })
      default: return ''
    }
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelApprovalSent ({ email }) {
    const currentLanguage = getCurrentLanguageStrings()
    let message = currentLanguage?.label?.approvalSent || languageFallback('Your approval request sent to askit@kone.com is waiting for the response')
    message = message.replace('askit@kone.com', escapeReplacementString(email))
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelApprovalResult ({ email, requestStatus }) {
    switch (requestStatus) {
      case APPROVED: return this.getLabelApprovalSent({ email })
      case PENDING: return this.getLabelApprovalPending({ email })
      case REJECTED: return this.getLabelApprovalRejected({ email })
      default: return ''
    }
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelArrived () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.arrived || languageFallback('Arrived')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelBroadcastMessageDescription () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.broadcastMessageDescription || languageFallback('The message written here will be sent to all users of the application')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelBroadcastMessage () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.broadcastMessage || languageFallback('Important message')
    return message
  },
  /**
   * title in the user profile important messages
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   */
  getLabelBroadcastMessages () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.broadcastMessages || languageFallback('Important messages')
    return message
  },
  /**
   * title in the user profile important messages
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   */
  getLabelBroadcastMessageTitleAlert () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.broadcastMessageTitleAlert || languageFallback('Important notice!')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelChangeLog () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.changeLog || languageFallback('CHANGELOG')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelCurrentUserApproved () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.currentUserApproved || languageFallback('Your approval request is approved')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelCurrentUserExpired () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.currentUserExpired || languageFallback('Sorry, looks like your account has expired.')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelDeleteConfirmation () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.deleteConfirmation || languageFallback('Are you sure you would like to delete this item?')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelEditExpirationForUser ({ userEmail }) {
    const currentLanguage = getCurrentLanguageStrings()
    let message = currentLanguage?.label?.editExpirationForUser || languageFallback('Edit user@example.com expiration date')
    message = message.replace('user@example.com', escapeReplacementString(userEmail))
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelEditPermissionsForUser ({ userEmail }) {
    const currentLanguage = getCurrentLanguageStrings()
    let message = currentLanguage?.label?.editPermissionsForUser || languageFallback('Edit user@example.com permissions')
    message = message.replace('user@example.com', escapeReplacementString(userEmail))
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelEditUserProfilePlannerGroup () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.editUserProfilePlannerGroup || languageFallback('Add or remove plannerGroup to supervisor permission')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelEditUserProfilePlant () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.editUserProfilePlant || languageFallback('Add or remove Plant to custom_service_admin, maintenance_technician, stock_admin, or supervisor permission')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelEditUserProfileShipToPartyNumber () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.editUserProfileShipToPartyNumber || languageFallback('Add or remove Ship to Party Number to PM_write permission')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelEditUserProfileStorageLocation () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.editUserProfileStorageLocation || languageFallback('Add or remove storageLocation:')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelEditUserProfileWorkCenter () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.editUserProfileWorkCenter || languageFallback('Add or remove Work Center to maintenance_technician permission')
    return message
  },

  /**
   * This function returns the label Email.
   * @return {string}
   */
  getLabelEmail () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.email || languageFallback('Email')
    return message
  },
  /**
   * This function returns the label Email Subject.
   * @return {string}
   */
  getLabelEmailSubject () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.emailSubject || languageFallback('Email Subject')
    return message
  },
  /**
   * This function returns the label Email Body.
   * @return {string}
   */
  getLabelEmailBody () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.emailBody || languageFallback('Email Body')
    return message
  },
  /**
   * This function returns the label Mobile.
   * @return {string}
   */
  getLabelMobile () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.mobile || languageFallback('Mobile')
    return message
  },
  /**
  * This function returns the label Mobile Number.
  * @return {string}
  */
  getLabelMobileNumber () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.mobileNumber || languageFallback('Mobile Number')
    return message
  },
  /**
  * This function returns the message Mobile Number Validation Error.
  * TODO: @Henrik: Request localization from KONE.
  * @return {string}
  */
  getMessageMobileNumberError () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.message?.mobileNumberError || languageFallback('The mobile phone number you entered is not valid. Please enter a valid mobile phone number starting with the country code. For example +3585012345678')
    return message
  },
  /**
   * This function returns the label for Primary settings in the notification preferences.
   * @return {string}
   */
  getLabelPrimarySettings () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.primarySettings || languageFallback('Primary Settings')
    return message
  },
  /**
   * This function returns the label for Secondary settings in the notification preferences.
   * @return {string}
   */
  getLabelSecondarySettings () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.secondarySettings || languageFallback('Secondary Settings')
    return message
  },
  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelEmailFormatError () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.emailFormatError || languageFallback('Email must have one @ character surrounded by non-@ characters.')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelEmailDescriptionForAllUsers () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.emailDescriptionForAllUsers || languageFallback('Full email address of the user you wish to view')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelEmailDomainNotSupported ({ domain }) {
    const currentLanguage = getCurrentLanguageStrings()
    let message = currentLanguage?.label?.emailDomainNotSupported || languageFallback('Email domain example.com not supported')
    message = message.replace('example.com', escapeReplacementString(domain))
    return message
  },
  /**
   * Returns the string for the label Sms.
   * @returns {string}
   */
  getLabelSms () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.sms || languageFallback('SMS')
    return message
  },
  /**
   * Returns the string for the label Sms.
   * @returns {string}
   */
  getLabelSmsBody () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.smsBody || languageFallback('SMS Body')
    return message
  },
  /**
   * Returns the string for the label Push Notification.
   * @returns {string}
   * TODO: @Henrik: Request localization from KONE.
   */
  getLabelPushNotification () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.pushNotification || languageFallback('Push Notification')
    return message
  },
  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelEmptyPendingApprovals () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.emptyPendingApprovals || languageFallback('There are no pending approvals')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelEndOfResults () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.endOfResults || languageFallback('End of results')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelFeedbackForm () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.feedbackForm || languageFallback('Feedback Form')
    return message
  },
  /**
   * This label is used for the feedback form to ask for rating.
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   */
  getLabelPleaseRateKPT () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.pleaseRateKPT || languageFallback('Please rate KPT')
    return message
  },
  /**
   * This label to identify the rating field.
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   */
  getFieldRating () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.feedbackRating || languageFallback('Rating')
    return message
  },
  /**
   * This label to identify the open feedback.
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   */
  getFieldOpenFeedback () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.field?.openFeedback || languageFallback('Open Feedback')
    return message
  },
  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelFeedbackFormAddress () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.feedbackFormAddress || languageFallback('https://docs.google.com/forms/d/e/1FAIpQLSdTUCPXRDFlTr_WpzLdTZffEB9ctu3ESw0edYfermcG1aBi7Q/viewform')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelFeedbackFormIframesDisabled () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.feedbackFormIframesDisabled || languageFallback('Enable iframes to send feedback.')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelFetchingData () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.fetchingData || languageFallback('Fetching data...')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelFetchingSuggestions () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.fetchingSuggestions || languageFallback('Fetching suggestions')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelFileProcessingTimeLimit () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.fileProcessingTimeLimit || languageFallback('Processing the file can take up to 10 minutes')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelGettingUserInfo () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.gettingUserInfo || languageFallback('Getting information about user ...')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelGreenHousePointIconsForLocation () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.greenHousePointIconsForLocation || languageFallback('GSS or KONE Spares delivery is recorded and if tracking exists then item has "delivered" status from freight forwarded, OR, if tracking is not available then when goods receipt (GR) is posted on purchase order. (House icon = normal delivery , point icon= AccessPoint delivery).')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelGreenHousePointIcons () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.greenHousePointIcons || languageFallback('green house / point icon')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelGreenIconForConfirmation () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.greenIconForConfirmation || languageFallback('Confirmation on purchase order (AB) or GSS sales order schedule details available')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelGreenIconForDelivery () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.greenIconForDelivery || languageFallback('Delivery created in GSS or KONE Spares and tracking data connected from freight forwarder.')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelGreenIcon () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.greenIcon || languageFallback('green icon')
    return message
  },

  /**
   * This string is used in information page delivery as one of the delivery icons
   * Only SLS currently uses this icon.
   * @return {string}
   */
  getLabelRediconForDelivery () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.redIconForDelivery || languageFallback('Delivery failed after tracking data connected from freight forwarder.')
    return message
  },

  /**
   * This string is used in information page delivery as one of the delivery icons
   * Only SLS currently uses this icon.
   * @return {string}
   */
  getLabelRedIcon () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.redIcon || languageFallback('red icon')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelGreyHousePointIconsForLocation () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.greyHousePointIconsForLocation || languageFallback('Proof of delivery not yet recorded (House icon = normal delivery , point icon= AccessPoint delivery). (1) If GSS delivery with tracking exists, icon is grey until item is delivered to pick up location. (2) If GSS or KONE Spares delivery without tracking is recorded but goods receipt (GR) is posted on purchase order less than 5 days ago. (3) In case of no connected delivery from GSS or KONE Spares then when shipping notification (LA) is recorded on purchase order, but no goods receipt (GR) recorded on purchase order.')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelGreyHousePointIcons () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.greyHousePointIcons || languageFallback('grey house / point icon')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelGreyIconForConfirmation () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.greyIconForConfirmation || languageFallback('Next status (Delivery/Shipping confirmation(LA)) is recorded for item')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelGreyiconForDelivery () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.greyiconForDelivery || languageFallback('Delivery has been completed. If delivery is connected to tracking from freight forwarder, item has "delivered" status from tracking. If no tracking data was connected, then item has Goods Receipt (GR) recorded on purchase order')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelGreyIcon () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.greyIcon || languageFallback('grey icon')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelGss () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.gss || languageFallback('GSS')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelIconListTitleForConfirmation () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.iconListTitleForConfirmation || languageFallback('Confirmation Icons')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelIconListTitleForDelivery () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.iconListTitleForDelivery || languageFallback('Delivery Icons')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelIconListTitleForLocation () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.iconListTitleForLocation || languageFallback('Location Icons')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelIncomingDeliveries () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.incomingDeliveries || languageFallback('Incoming deliveries')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelInstructions () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.instructions || languageFallback('Instructions')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelInstructionsLink () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.instructionsLink || languageFallback('Detailed instructions can be found on SharePoint.')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelInstructionsLinkAddress () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.instructionsLinkAddress || languageFallback('https://kone.sharepoint.com/:f:/s/GSSSupplyChainPlanning/Evv8DHXjLltFjZauBANiWQsBACI5Zif04kc4xu74EwetlA?e=WpMi6u')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelLoading () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.loading || languageFallback('Loading...')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   */
  getLabelMessage () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.message || languageFallback('Message')
    return message
  },
  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelLocal () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.local || languageFallback('Local')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelListingItems () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.listingItems || languageFallback('Listing items...')
    return message
  },

  /**
   * This string is used to show a link to the material in kone parts.
   * @return {string}
   */
  getLabelMaterialCodeAddress ({ materialCode }) {
    const currentLanguage = getCurrentLanguageStrings()
    let message = currentLanguage?.label?.materialCodeAddress || languageFallback('https://parts.kone.com/Products/1234567890')
    message = message.replace('1234567890', escapeReplacementString(materialCode))
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelMissingIconForConfirmation () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.missingIconForConfirmation || languageFallback('No active confirmation for the item')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelMissingIconForDelivery () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.missingIconForDelivery || languageFallback('Confirmation on purchase order (AB) or sales order schedule details available, but no delivery/shipping confirmation (LA) recorded for item')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelMissingIconForLocation () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.missingIconForLocation || languageFallback('No delivery address recorded for the item. (House icon= normal delivery, Point icon = AccessPoint delivery)')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelMissingIcon () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.missingIcon || languageFallback('missing icon')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelMyActionPoints () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.myActionPoints || languageFallback('My Action points')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelNa () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.na || languageFallback('NA')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelNoAddressCode () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.noAddressCode || languageFallback('No address code')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelNoDataFound () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.noDataFound || languageFallback('No data found')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelNoDescription () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.noDescription || languageFallback('No description')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelNoEtaDate () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.noEtaDate || languageFallback('No ETA date')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelNoItemsFound () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.noItemsFound || languageFallback('No items found')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelNoMaterialCode () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.noMaterialCode || languageFallback('No Material Code')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelNoMaterialDescription () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.noMaterialDescription || languageFallback('No Material Description')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelNoPurchaseOrder () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.noPurchaseOrder || languageFallback('Purchase order missing')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelNoServiceOrder () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.noServiceOrder || languageFallback('Service Order not found')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelNoShipToPartyNumbers () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.noShipToPartyNumbers || languageFallback('There are no Ship To Party Numbers available')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelNoSubtitle () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.noSubtitle || languageFallback('No subtitle')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelNoteIntro () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.noteIntro || languageFallback('Note')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelNoTitle () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.noTitle || languageFallback('No title')
    return message
  },

  /**
   * returns the label Title.
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   */
  getLabelTitle () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.Title || languageFallback('Title')
    return message
  },
  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelNoUnit () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.noUnit || languageFallback('No unit')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelNoUsers () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.noUsers || languageFallback('There are no users')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelOrderDetails () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.orderDetails || languageFallback('Order Details')
    return message
  },
  getLabelNotifications () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.notifications || languageFallback('Notifications')
    return message
  },
  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelOutOfStock () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.outOfStock || languageFallback('out of stock')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelPendingApprovals () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.pendingApprovals || languageFallback('Pending Approvals')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelPermissionList () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.permissionList || languageFallback('Available perissions to set or unset')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelRecentSearches () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.recentSearches || languageFallback('Recent searches')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelRelatedValuesSeparator () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.relatedValuesSeparator || languageFallback('/')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelSearchForAccessPoint () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.searchForAccessPoint || languageFallback('Search for access points')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelSearchForAccessPointLimitedByCountries () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.searchForAccessPointLimitedByCountries || languageFallback('Search for access points (Suggestions are limited to Countries currently)')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelSearchForStatus () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.searchForStatus || languageFallback('Search for Customers, Locations ...')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelSearchForStock () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.searchForStock || languageFallback('Search for material codes/description or work center')
    return message
  },

  /**
   * This text is a placeholder in search box or below search box in mobile UI.
   * @return {string}
   */
  getLabelSearchForMaterialCount () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.searchForMaterialCount || languageFallback('Search for material codes/descriptions')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelSearch () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.search || languageFallback('Search')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelSearchInstructionDetails () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.searchInstructionDetails || languageFallback('Locates all the items where search words is present in any of the fields. Search with any KONE order or locations references (full or partial), or freight forwarders tracking number. Do not use spaces between words or special characters (%, *, ?,…) to cut the word, but enter each search word separately to drill down with search results.')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelSearchInstructionSummary () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.searchInstructionSummary || languageFallback('Free text search')
    return message
  },
  /**
   * Used in info page
   * @return {string}
   */
  getLabelIndexedSearchSummary () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.IndexedSearchSummary || languageFallback('Indexed Search')
    return message
  },
  /**
   * Used in info page
   * @return {string}
   */
  getLabelIndexedSearchDetails () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.IndexedSearchDetails || languageFallback('Select the search word and "add" required search criteria\'s (Planner Group=P04) and press "search".')
    return message
  },
  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelSearchResultsFor () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.searchResultsFor || languageFallback('Search results for')
    return message
  },
  /**
   * This function returns the text placeholder for stock count search box .
   * @return {string}
   */
  getLabelSearchNotificationStockCount () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.searchNotificationStockCount || languageFallback('Search for materials, codes, descriptions or work center...')
    return message
  },
  /**
   * This function returns the text placeholder for SMS search box .
   * @return {string}
   */
  getLabelSearchNotificationSMS () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.searchNotificationSMS || languageFallback('Search for SMS...')
    return message
  },
  /**
   * This function returns the text placeholder for email search box .
   * @return {string}
   */
  getLabelSearchNotificationEmail () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.searchNotificationEmail || languageFallback('Search for EMAIL...')
    return message
  },
  /**
   * This function returns the text placeholder for PUSH search box .
   * @return {string}
   */
  getLabelSearchNotificationPush () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.searchNotificationSMS || languageFallback('Search for PUSH...')
    return message
  },
  /**
   * This function returns the text placeholder for sent feedback search box .
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   */
  getLabelSearchNotificationSentFeedback () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.searchNotificationSentFeedback || languageFallback('Search for sent feedback...')
    return message
  },
  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelSelectedFile () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.selectedFile || languageFallback('Selected File')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelServiceOrderStage () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.serviceOrderStage || languageFallback('Service order stage')
    return message
  },

  /**
   * Returns welcome text from sign-in page.
   *
   * Note: Getting an actual localization for this does not currently (as of
   * 2022-04-25) make sense, because FirebaseUI for Web does not support
   * dynamic language change.
   *
   * See: {@link https://github.com/firebase/firebaseui-web/issues/789}
   *
   * (See also: `getPageLandingHeader`.)
   *
   * @return {string}
   */
  getLabelSignIn () {
    // const currentLanguage = getCurrentLanguageStrings()
    // const message = currentLanguage?.label?.signIn || languageFallback('Welcome, please sign in.')
    const message = 'Welcome, please sign in.'
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelStatus ({ status }) {
    switch (status) {
      case AT_ACCESS_POINT: return this.getLabelStatusAtAccessPoint()
      case DELIVERED: return this.getLabelStatusDelivered()
      case ON_THE_WAY: return this.getLabelStatusOnTheWay()
      // Since this list might not be exhaustive, it's better to play it safe
      // and return the raw value as a fallback.
      default: return status
    }
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelStatusAtAccessPoint () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.statusAtAccessPoint || languageFallback('At access point')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelStatusDelivered () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.statusDelivered || languageFallback('Delivered')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelStatusOnTheWay () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.statusOnTheWay || languageFallback('On the way')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelUnknownValueMarker () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.unknownValueMarker || languageFallback('?')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelUserApprovalRequest () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.userApprovalRequest || languageFallback('User Approval request')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelUserApproved ({ email }) {
    const currentLanguage = getCurrentLanguageStrings()
    let message = currentLanguage?.label?.userApproved || languageFallback('This approval request was created by request-user@example.com and is already accepted.')
    message = message.replace('request-user@example.com', escapeReplacementString(email))
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelUserExpirationDate () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.userExpirationDate || languageFallback('User expiration date')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelUserRejected ({ email }) {
    const currentLanguage = getCurrentLanguageStrings()
    let message = currentLanguage?.label?.userRejected || languageFallback('This approval request was created by request-user@example.com and is already rejected.')
    message = message.replace('request-user@example.com', escapeReplacementString(email))
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelUserRemovalConfirmation ({ email }) {
    const currentLanguage = getCurrentLanguageStrings()
    let message = currentLanguage?.label?.userRemovalConfirmation || languageFallback('Are you sure you would like to remove user@example.com?')
    message = message.replace('user@example.com', escapeReplacementString(email))
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelWelcomeToLogisticsSystem () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.welcomeToLogisticsSystem || languageFallback('Welcome to logistics system')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelWelcomeWords () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.welcomeWords || languageFallback('In order to be able to access the information you need the approval of your support team. Please submit the approval request below.')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelYellowIconForConfirmation () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.yellowIconForConfirmation || languageFallback('Purchase order created, but no confirmation on order (AB), or no GSS Sales order schedule details available')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelYellowIconForDelivery () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.yellowIconForDelivery || languageFallback('Delivery has been created in GSS or KONE Spares, but no tracking data connected from freight forwarders, OR shipping confirmation (LA) is recorded on PO')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelYellowIcon () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.yellowIcon || languageFallback('yellow icon')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getLabelZeroQuantityItemsHiddenWhenSearching () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.zeroQuantityItemsHiddenWhenSearching || languageFallback('Items with quantity of 0 are hidden when searching.')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getMobileTabAllOrders () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.mobileTab?.allOrders || languageFallback('All service orders')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getPageApprovalRequestHeader () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pageApprovalRequest?.header || languageFallback('APPROVAL REQUEST')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getPageInfoHeader () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pageInfo?.header || languageFallback('INFORMATION')
    return message
  },

  /**
   * Returns title of sign-in page.
   *
   * Note: Getting an actual localization for this does not currently (as of
   * 2022-04-25) make sense, because FirebaseUI for Web does not support
   * dynamic language change.
   *
   * See: {@link https://github.com/firebase/firebaseui-web/issues/789}
   *
   * (See also: `getLabelSignIn`.)
   *
   * @return {string}
   */
  getPageLandingHeader () {
    // const currentLanguage = getCurrentLanguageStrings()
    // const message = currentLanguage?.pageLanding?.header || languageFallback('LANDING PAGE')
    const message = 'LANDING PAGE'
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getPageOrderDetailsHeader () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pageOrderDetails?.header || languageFallback('ORDER DETAILS')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getPagePriorityHeader () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pagePriority?.header || languageFallback('PRIORITY MANAGER')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getPageModalUploadShipToPartyUapPrioritiesTitle () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pagePriority?.modalUploadShipToPartyUapPrioritiesTitle || languageFallback('Upload ShipToParty-UAP priorities')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getPageModalUploadShipToPartyUapPrioritiesText () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pagePriority?.modalUploadShipToPartyUapPrioritiesText || languageFallback('Select a CSV file to update priorities for multiple access points')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getPageModalUploadShipToPartyNumbersTitle () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pagePriority?.modalUploadShipToPartyNumbersTitle || languageFallback('Upload ShipToParty-Numbers')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getPageModalUploadShipToPartyNumbersText () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pagePriority?.modalUploadShipToPartyNumbersText || languageFallback('Select a CSV file to delete multiple access points')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getPageSearchStockManagementHeader () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pageSearchStockManagement?.header || languageFallback('SEARCH STOCK MANAGEMENT')
    return message
  },

  /**
   * Used for searching material inside stock count
   * @return {string}
   */
  getPageSearchMaterialCountHeader () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pageSearchMaterialCount?.header || languageFallback('SEARCH MATERIAL COUNT')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getPageStatusFilter () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pageStatus?.filter || languageFallback('Filter by')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getPageStatusHeader () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pageStatus?.header || languageFallback('STATUS MONITOR')
    return message
  },
  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getPageStatusMyOrders () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pageStatus?.myOrders || languageFallback('MY ORDERS')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getPageStatusTabAll () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pageStatus?.tabAll || languageFallback('ALL')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getPageStatusTabArrived () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pageStatus?.tabArrived || languageFallback('ARRIVED')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getPageStatusTabIncoming () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pageStatus?.tabIncoming || languageFallback('INCOMING')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getPageStockHeader () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pageStock?.header || languageFallback('STOCK MANAGEMENT')
    return message
  },
  /**
   * Get notifications page header
   * @return {string}
   */
  getPageNotificationsHeader () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pageStatus?.notifications || languageFallback('NOTIFICATIONS')
    return message
  },
  /**
   * Get notifications page stock count tab
   * @return {string}
   * */
  getPageNavigationsTabStockCount () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pageStockCount?.tabStockCount || languageFallback('STOCK COUNT')
    return message
  },
  /**
   * Get notifications page SMS tab
   * @return {string}
   * */
  getPageNavigationsTabSMS () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pageStatus?.tabSMS || languageFallback('SMS')
    return message
  },
  /**
   * Get notifications page email tab
   * @return {string}
   * */
  getPageNavigationsTabEmail () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pageStatus?.tabEmail || languageFallback('EMAIL')
    return message
  },
  /**
   * Get notifications page email tab
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   * */
  getPageNavigationsTabPushNotification () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pageStatus?.tabPushNotification || languageFallback('PUSH')
    return message
  },
  /**
   * Get notifications page sent feedback tab
   * @return {string}
   * */
  getPageNavigationsTabSentFeedback () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pageStatus?.tabSentFeedback || languageFallback('SENT FEEDBACK')
    return message
  },
  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getPageMaterialCountHeader () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pageMaterialCount?.header || languageFallback('MATERIAL COUNT')
    return message
  },
  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getPageStockTabMyPlantStock () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pageStock?.tabMyPlantStock || languageFallback('MY PLANT STOCK')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getPageStockTabMyStock () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pageStock?.tabMyStock || languageFallback('MY STOCK')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getPageStockTabMyTeamStock () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pageStock?.tabMyTeamStock || languageFallback('MY TEAM STOCK')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getPageTitle () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pageTitle || languageFallback('KONE Parts Tracker')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getPageUserExpiredHeader () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pageUserExpired?.header || languageFallback('USER EXPIRED')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getPageUserProfileHeader () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pageUserProfile?.header || languageFallback('USER PROFILE')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getPageUsersHeader () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pageUsers?.header || languageFallback('USERS MANAGEMENT')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getPageUsersTabAllUsers () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pageUsers?.tabAllUsers || languageFallback('All Users')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getPageUsersTabApprovedUsers () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pageUsers?.tabApprovedUsers || languageFallback('Approved Users')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getPageUsersTabMyPlantUsers () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pageUsers?.tabMyPlantUsers || languageFallback('My Plant Users')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getPageWelcomeHeader () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.pageWelcome?.header || languageFallback('Welcome to KONE Parts Tracker')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getProfileLogoAddress () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.profileLogoAddress || languageFallback('https://storage.googleapis.com/cp-logistics-assets/KONE.hzcP9m9Y3GjBgi4PDJ1rDuvCbCeLjq7g.svg')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getRoleCustomerServiceAdmin () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.role?.customerServiceAdmin || languageFallback('Customer service admin')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getRoleMechanic () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.role?.mechanic || languageFallback('Maintenance technician')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getRoleSelectionDescription () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.role?.selectionDescription || languageFallback('Please select your role')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getRoleSupervisor () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.role?.supervisor || languageFallback('Supervisor')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getTabHeaderInfo () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tabHeader?.info || languageFallback('INFO')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getTabheaderLogout () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tabHeader?.logout || languageFallback('LOGOUT')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getTabHeaderPriority () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tabHeader?.priority || languageFallback('PRIORITY')
    return message
  },
  /**
   * Get Mechanic page header. This page shows the materials that are being counted.
   * @return {string}
   */
  getPageMechanicViewHeader () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tabHeader?.stockCount || languageFallback('STOCK COUNT')
    return message
  },
  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getTabHeaderProfile () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tabHeader?.profile || languageFallback('PROFILE')
    return message
  },
  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getTabHeaderStatus () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tabHeader?.status || languageFallback('STATUS')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getTabHeaderStock () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tabHeader?.stock || languageFallback('STOCK')
    return message
  },
  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getTabHeaderUsers () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tabHeader?.users || languageFallback('USERS')
    return message
  },
  /**
   * Get tab header notifications.
   * @return {string}
   */
  getTabHeaderNotifications () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tabHeader?.notifications || languageFallback('NOTIFICATIONS')
    return message
  },
  /**
  * Get tab header STOCK COUNT.
  * @return {string}
  */
  getTabHeaderStockCount () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tabHeader?.stockCount || languageFallback('STOCK COUNT')
    return message
  },
  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getTooltipAccessPointPriorityGrantRead () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tooltip?.accessPointPriorityGrantRead || languageFallback('Be able to give PM_read to users in the user management.')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getTooltipAccessPointPriorityGrantWrite () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tooltip?.accessPointPriorityGrantWrite || languageFallback('Be able to give PM_write to users in the user management.')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getTooltipAccessPointPriorityRead () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tooltip?.accessPointPriorityRead || languageFallback('Be able to view the access point priorities for each ship to party code.')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getTooltipAccessPointPriorityUploadAdd () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tooltip?.accessPointPriorityUploadAdd || languageFallback('Be able to add ship to party codes with the access point priorities, by uploading a CSV file.')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getTooltipAccessPointPriorityUploadDsiable () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tooltip?.accessPointPriorityUploadDsiable || languageFallback('Be able to disable the access point priorities for each ship to party codes, by uploading a CSV file.')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getTooltipAccessPointPriorityWrite () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tooltip?.accessPointPriorityWrite || languageFallback('Be able to view and modify the access point priorities for each ship to party code.')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getTooltipCustomerServiceAdmin () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tooltip?.customerServiceAdmin || languageFallback('Generic role for a local admin, who is responsible for maintaining the frontline.')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getTooltipEditExpirationDate () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tooltip?.editExpirationDate || languageFallback('Edit expiration date')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getTooltipEditPermissions () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tooltip?.editPermissions || languageFallback('Edit permissions')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getTooltipMechanic () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tooltip?.mechanic || languageFallback('Maintenance technician is the handyman, who does the ground work for the maintenance.')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getTooltipSmsSentTo ({ phoneNumber }) {
    const currentLanguage = getCurrentLanguageStrings()
    let message = currentLanguage?.tooltip?.smsSentTo || languageFallback('SMS sent to +123456789')
    message = message.replace('+123456789', escapeReplacementString(phoneNumber))
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getTooltipStockAdmin () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tooltip?.stockAdmin || languageFallback('Local or frontline admins, who are responsible for the stock planning, leveling, counting, and so on')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getTooltipSuperAdmin () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tooltip?.superAdmin || languageFallback('Top level admin user, who has read and write access to everything.')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getTooltipSuperAdminReadOnly () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tooltip?.superAdminReadOnly || languageFallback('Local or frontline admins, who are responsible for the stock planning, leveling, counting, and so on')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getTooltipSupervisor () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tooltip?.supervisor || languageFallback('Leader of a local (or frontline) level team of maintenance technicians.')
    return message
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @return {string}
   */
  getTooltipUserAcceptance () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tooltip?.userAcceptance || languageFallback('Role for managing access rights for the users.')
    return message
  },

  /**
   * label for the notification prompt that would appear in firefox
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   * */
  getNotificationPromptYesText () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.notificationPrompt?.yesText || languageFallback('Yes')
    return message
  },
  /**
   * label for the notification prompt that would appear in firefox
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   * */
  getNotificationPromptNoText () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.notificationPrompt?.noText || languageFallback('No')
    return message
  },
  /**
   * label for the notification prompt that would appear in firefox
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   * */
  getNotificationPromptNeverText () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.notificationPrompt?.neverText || languageFallback('Don\'t ask again')
    return message
  },
  /**
   * show error for item that is less than zero
   * TODO: @Henrik: Request localization from KONE.
   * @returns {string}
   */
  getErrorQuantityGreaterThanZero () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.error?.quantityGreaterOrEqualZero || languageFallback('Quantity should be greater than or equal to 0')
    return message
  },
  /**
   * label for PLANT column
   * TODO: @Henrik: Request localization from KONE.
   * @returns {string}
   * */
  getTableColumnPlant () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tableColumn?.plant || languageFallback('Plant')
    return message
  },
  /**
 * Label for STORAGE_LOCATION column
 * TODO: @Henrik: Request localization from KONE.
 * @returns {string}
 */
  getTableColumnStorageLocation () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tableColumn?.storageLocation || languageFallback('Storage Location')
    return message
  },
  /**
  * Label for EQUIPMENT_CODE column
  * TODO: @Henrik: Request localization from KONE.
  * @returns {string}
  */
  getTableColumnEquipmentCode () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tableColumn?.equipmentCode || languageFallback('Equipment Code')
    return message
  },
  /**
  * Label for EQUIPMENT_DESCRIPTION column
  * TODO: @Henrik: Request localization from KONE.
  * @returns {string}
  */
  getTableColumnEquipmentDescription () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tableColumn?.equipmentDescription || languageFallback('Equipment Description')
    return message
  },
  /**
   * Label for WORK_CENTER column
   * TODO: @Henrik: Request localization from KONE.
   * @returns {string}
   */
  getTableColumnWorkCenter () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tableColumn?.workCenter || languageFallback('Work Center')
    return message
  },
  /**
  * Label for PLANNER_GROUP column
  * TODO: @Henrik: Request localization from KONE.
  * @returns {string}
  */
  getTableColumnPlannerGroup () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tableColumn?.plannerGroup || languageFallback('Planner Group')
    return message
  },
  /**
  * Label for TRACKING_NUMBER column
  * TODO: @Henrik: Request localization from KONE.
  * @returns {string}
  */
  getTableColumnTrackingNumber () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tableColumn?.trackingNumber || languageFallback('Tracking Number')
    return message
  },
  /**
   * Label for PURCHASE_ORDER column
   * TODO: @Henrik: Request localization from KONE.
   * @returns {string}
   */
  getTableColumnPurchaseOrder () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tableColumn?.purchaseOrder || languageFallback('Purchase Order')
    return message
  },
  /**
  * Label for SALES_ORDER column
  * TODO: @Henrik: Request localization from KONE.
  * @returns {string}
  */
  getTableColumnSalesOrder () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tableColumn?.salesOrder || languageFallback('Sales Order')
    return message
  },
  /**
  * Label for DELIVERY_ORDER column
  * TODO: @Henrik: Request localization from KONE.
  * @returns {string}
  */
  getTableColumnDeliveryOrder () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tableColumn?.deliveryOrder || languageFallback('Delivery Order')
    return message
  },
  /**
   * Label for SERVICE_ORDER column
   * TODO: @Henrik: Request localization from KONE.
   * @returns {string}
   */
  getTableColumnServiceOrder () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tableColumn?.serviceOrder || languageFallback('Service Order')
    return message
  },
  /**
  * Label for MATERIAL_CODE column
  * TODO: @Henrik: Request localization from KONE.
  * @returns {string}
  */
  getTableColumnMaterialCode () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tableColumn?.materialCode || languageFallback('Material Code')
    return message
  },
  /**
  * Label for MATERIAL_CODE column
  * TODO: @Henrik: Request localization from KONE.
  * @returns {string}
  */
  getTableColumnMaterialCodes () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tableColumn?.materialCodes || languageFallback('Material Codes')
    return message
  },
  /**
  * Label for MATERIAL_DESCRIPTION column
  * TODO: @Henrik: Request localization from KONE.
  * @returns {string}
  */
  getTableColumnMaterialDescription () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tableColumn?.materialDescription || languageFallback('Material Description')
    return message
  },
  /**
   * Label for DELIVERY_DATE column
   * TODO: @Henrik: Request localization from KONE.
   * @returns {string}
   */
  getTableColumnDeliveryDate () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tableColumn?.deliveryDate || languageFallback('Delivery Date')
    return message
  },
  /**
  * Label for PHONE_NUMBER column
  * TODO: @Henrik: Request localization from KONE.
  * @returns {string}
  */
  getTableColumnPhoneNumber () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tableColumn?.phoneNumber || languageFallback('Phone Number')
    return message
  },
  /**
  * Label for CREATED column
  * TODO: @Henrik: Request localization from KONE.
  * @returns {string}
  */
  getTableColumnCreated () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tableColumn?.created || languageFallback('Created')
    return message
  },
  /**
   * Label for MESSAGE column
   * TODO: @Henrik: Request localization from KONE.
   * @returns {string}
   */
  getTableColumnMessage () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tableColumn?.message || languageFallback('Message')
    return message
  },
  /**
  * Label for SUBJECT column
  * TODO: @Henrik: Request localization from KONE.
  * @returns {string}
  */
  getTableColumnSubject () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tableColumn?.subject || languageFallback('Subject')
    return message
  },
  /**
  * Label for TITLE column
  * TODO: @Henrik: Request localization from KONE.
  * @returns {string}
  */
  getTableColumnTitle () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tableColumn?.title || languageFallback('Title')
    return message
  },
  /**
   * Label for EMAIL column
   * TODO: @Henrik: Request localization from KONE.
   * @returns {string}
   */
  getTableColumnEmail () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tableColumn?.email || languageFallback('Email')
    return message
  },
  /**
   * Label for Created By column
   * TODO: @Henrik: Request localization from KONE.
   * @returns {string}
   */
  getTableColumnCreatedBy () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tableColumn?.createdBy || languageFallback('Created By')
    return message
  },
  /**
   * Label for Conductor column
   * TODO: @Henrik: Request localization from KONE.
   * @returns {string}
   */
  getTableColumnConductor () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tableColumn?.conductor || languageFallback('Conductor')
    return message
  },
  /**
 * Label for Type column
 * TODO: @Henrik: Request localization from KONE.
 * @returns {string}
 */
  getTableColumnType () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tableColumn?.type || languageFallback('Type')
    return message
  },
  /**
   * Label for Deadline column
   * TODO: @Henrik: Request localization from KONE.
   * @returns {string}
   */
  getTableColumnDeadline () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tableColumn?.deadline || languageFallback('Deadline')
    return message
  },
  /**
  * Label for Status column
  * TODO: @Henrik: Request localization from KONE.
  * @returns {string}
  */
  getTableColumnStatus () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tableColumn?.status || languageFallback('Status')
    return message
  },
  /**
  * Label for Actions column
  * TODO: @Henrik: Request localization from KONE.
  * @returns {string}
  */
  getTableColumnActions () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tableColumn?.actions || languageFallback('Actions')
    return message
  },
  /**
  * Label for Counting type column
  * TODO: @Henrik: Request localization from KONE.
  * @returns {string}
  */
  getTableColumnCountingType () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tableColumn?.countingType || languageFallback('CountingType')
    return message
  },
  /**
   * label for Form Name column
   * TODO: @Henrik: Request localization from KONE.
   * @returns {string}
   * */
  getTableColumnFormName () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.tableColumn?.formName || languageFallback('Form Name')
    return message
  },
  /**
  * Label for Create Stock Count action
  * TODO: @Henrik: Request localization from KONE.
  * @returns {string}
  */
  getActionCreateStockCount () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.createStockCount || languageFallback('Create Stock Count')
    return message
  },
  /**
   * Label for Edit action
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   */
  getActionEdit () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.edit || languageFallback('Edit')
    return message
  },
  /**
   * Label for Save as Draft action
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   */
  getActionSaveAsDraft () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.saveDraft || languageFallback('Save as Draft')
    return message
  },
  /**
   * Label for Save as Draft action
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   */
  getActionSaveNSubmit () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.saveSubmit || languageFallback('Save & Submit')
    return message
  },
  /**
   * Label for validation field that quantity should be greater than zero
   * @return {string}
   */
  getLabelQuantityShouldBeGreaterThanZero () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.error?.quantityGreaterThanZero || languageFallback('Quantity should be greater than 0')
    return message
  },
  /**
   * Label for feedback submission successful
   * @return {string}
   */
  getLabelFeedbackSubmissionSuccessfulTitle () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.feedbackSubmissionSuccessfulTitle || languageFallback('Feedback Submitted Successfully')
    return message
  },
  /**
   * Label for Cancelled value
   * @return {string}
   * */
  getLabelCancelled () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.cancelled || 'Cancelled'
    return message
  },
  /**
   * Label for In Progress value
   * @return {string}
   * */
  getLabelInProgress () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.in_progress || 'In progress'
    return message
  },
  /**
   * Label for Error value
   * @return {string}
   * */
  getLabelError () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.error || 'Error'
    return message
  },
  /**
   * Label for Initiated value
   * @return {string}
   * */
  getLabelInitiated () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.initiated || 'Initiated'
    return message
  },
  /**
   * Label for Submitted value
   * @return {string}
   * */
  getLabelSubmitted () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.submitted || 'Submitted'
    return message
  },
  /**
   * Label for Completed value
   * @return {string}
   * */
  getLabelCompleted () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.completed || 'Completed'
    return message
  },
  /**
   * Label for Recount value
   * @return {string}
   * */
  getLabelRecountStatus () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.recount || languageFallback('Recount')
    return message
  },
  /**
   * Label for cancel request confirmation
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   */
  getLabelCancelRequestConfirmation () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.cancelRequestConfirmation || languageFallback('Are you sure you would like to cancel this request?')
    return message
  },
  /**
   * Label for Stock count cancelled
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   */
  getLabelStockCountCancelled () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.cancelStockCountRequestCancelled || languageFallback('Stock Count Request Cancelled')
    return message
  },
  /**
   * Label for Stock count cancelled
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   */
  getLabelStockCountCancelledSuccessfully () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.cancelStockCountRequestCancelledSuccessfully || languageFallback('Your Stock Count request has been cancelled successfully!')
    return message
  },
  /**
   * Label for Select Material Code Placeholder
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   */
  getLabelSelectMaterialCodePlaceHolder () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.placeholderSelectMaterialCodes || languageFallback('Select Material Code(s)')
    return message
  },
  /**
   * Label for Select Plant Placeholder
   * @returns {string}
   */
  getLabelSelectPlantPlaceHolder () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.placeholderSelectPlant || languageFallback('Select Plant')
    return message
  },
  /**
   * Label for Select Storage Location Placeholder
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   */
  getLabelSelectStorageLocationPlaceHolder () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.placeholderSelectStorageLocation || languageFallback('Select Storage Location(s)')
    return message
  },
  /**
   * Label for Select Storage Location Placeholder
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   */
  getLabelSelectWorkCenterPlaceHolder () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.placeholderSelectWorkCenter || languageFallback('Select Work Center(s)')
    return message
  },
  /**
   * Add custom storage location label
   * @returns {string}
   * */
  getLabelAddCustomStorageLocation () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.addCustomStorageLocation || languageFallback('Add Custom Storage Location')
    return message
  },
  /**
   * Label for Enter your text placeholder
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   */
  getLabelEnterTextPlaceHolder () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.placeholderEnterText || languageFallback('Enter your text')
    return message
  },
  /**
   * Label for Selected Material Code
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   */
  getLabelSelectedMaterialCode () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.selectedMaterialCodes || languageFallback('Material Code(s) selected')
    return message
  },
  /**
   * Label for All fields are Mandatory
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   */
  getLabelAllFieldsMandatory () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.allFieldsMandatory || languageFallback('All fields are mandatory!')
    return message
  },
  /**
   * Label for Send Request
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   */
  getLabelSendRequest () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.sendRequest || languageFallback('Send Request')
    return message
  },
  /**
   * Label for I Don't Know
   * @returns {string}
   */
  getLabelIDontKnow () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.iDontKnow || languageFallback('I Don\'t Know')
    return message
  },
  /**
   * Label for Single
   * @returns {string}
   */
  getLabelSingle () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.single || languageFallback('Single')
    return message
  },
  /**
   * Label for Wall to Wall
   * @returns {string}
   * */
  getLabelWallToWall () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.wallToWall || languageFallback('Wall to Wall')
    return message
  },
  /**
   * label for Press Enter to select
   * @returns {string}
   * */
  getPressEnterToSelectLabel () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.pressEnterToSelect || languageFallback('Press Enter to select')
    return message
  },
  /**
   * label for Press Enter to remove
   * @returns {string}
   * */
  getPressEnterToRemoveLabel () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.pressEnterToRemove || languageFallback('Press Enter to remove')
    return message
  },
  /**
   * label for Selected
   * @returns {string}
   **/
  getSelectedLabel () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.selected || languageFallback('Selected')
    return message
  },
  /**
   * label for Use cursor keys to navigate calendar dates
   * @returns {string}
   * */
  getUseCursorKeysToNavigateCalendarDatesLabel () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.useCursorKeysToNavigateCalendarDates || languageFallback('Use cursor keys to navigate calendar dates')
    return message
  },
  /**
   * label for Stock Count Created
   * @returns {string}
   * */
  getStockCountCreatedLabel () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.stockCountCreated || languageFallback('Stock Count Created!')
    return message
  },
  /**
   * label for Stock Count Creation Failed
   * @returns {string}
   * */
  StockCountCreationFailedLabel () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.stockCountCreationFailed || languageFallback('Stock Count Creation Failed!')
    return message
  },
  /**
   * label for Add Preference
   * returns {string}
   * */
  getAddPreferenceLabel () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.addPreference || languageFallback('Add Preference')
    return message
  },
  /**
   * label for the stock count request title
   * @returns {string}
   */
  getLabelCreateStockCountRequest () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.createStockCountRequest || languageFallback('Create Stock Count Request')
    return message
  },
  /**
   * Label for Counting Request
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   */
  getLabelCountingRequest () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.countingRequest || languageFallback('Counting Request')
    return message
  },
  /**
   * Label for Counting Request Submitted Successfully
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   */
  getLabelCountingRequestSubmittedSuccessfully () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.countingRequestSubmittedSuccessfully || languageFallback('Your counting request has been submitted successfully!')
    return message
  },
  /**
   * Label for Counting Request Saved As Draft
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   */
  getLabelCountingRequestSavedAsDraft () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.countingRequestSubmittedSuccessfully || languageFallback('Your counting request has been saved as a Draft! You can edit the draft from the count list.')
    return message
  },
  /**
   * Label for Work Center Selected
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   */
  getLabelWorkCenterSelected () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.workCenterSelected || languageFallback('Work Center(s) selected')
    return message
  },
  /**
   * Label for Storage Location Selected
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   */
  getLabelStorageLocationSelected () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.storageLocationSelected || languageFallback('Storage Location(s) selected')
    return message
  },
  /**
   * Label for Storage Location Selected
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   */
  getLabelMaterialCodeSelected () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.materialCodeSelected || languageFallback('Material Code(s) selected')
    return message
  },
  /**
   * Label for Physical Inventory Document Number
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   *
   */
  getLabelPhysicalInventoryDocumentNumber () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.physicalInventoryDocumentNumber || languageFallback('Physical Inventory Document Number')
    return message
  },
  /**
   * Label for filter label for delivery notification
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   *
   */
  getLabelFilterDeliveryNotification () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.filterDeliveryNotification || languageFallback('Delivery Notification')
    return message
  },
  /**
   * Label for filter label for inventory count notification
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   *
   */
  getLabelFilterInventoryCountNotification () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.filterInventoryCountNotification || languageFallback('Inventory Count Notification')
    return message
  },
  /**
 * Label for filter label for contact notification
 * TODO: @Henrik: Request localization from KONE.
 * @return {string}
 *
 */
  getLabelFilterContactNotification () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.filterContactNotification || languageFallback('Contact Notification')
    return message
  },
  /**
   * Label for filter label for other notification
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   *
   */
  getLabelFilterOtherNotification () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.filterOtherNotification || languageFallback('Other Notification')
    return message
  },
  /**
   * Label for filter label for All options
   * TODO: @Henrik: Request localization from KONE.
   * @return {string}
   *
   */
  getLabelFilterAllNotification () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.filterAllOptions || languageFallback('All')
    return message
  },
  /**
   * label for Permission Cell for ship to party
  * TODO: @Henrik: Request localization from KONE.
  * @return {string}
  */
  getLabelEditUserProfileShipToPartyLimited (limit) {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.editUserProfileShipToPartyNumberLimited || languageFallback('Add or remove 1 Ship to Party Number(s)')
    return message.replace('1', escapeReplacementString(String(limit)))
  },
  /**
   * label for warning storage location reset
  * TODO: @Henrik: Request localization from KONE.
  * @return {string}
  */
  getLabelStorageLocationResetWarning (limit) {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.label?.storageLocationResetWarning || languageFallback('NOTE: Storage Location(s) will be reset on change.')
    return message
  },
  /**
   * Label for action button Submit
   * @return {string}
   */
  getActionButtonSubmitStockCount () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.submitStockCount || languageFallback('Submit Stock Count')
    return message
  },
  /**
   * Label for action button Cancel
   * @return {string}
   * */
  getActionButtonCancel () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.cancel || languageFallback('Cancel')
    return message
  },
  /**
   * Label for action button Add Material
   * @return {string}
   * */
  getActionButtonAddMaterial () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.addMaterial || languageFallback('Add Material')
    return message
  },
  /**
   * Label for action button Print Materials
   * @return {string}
   * */
  getActionButtonPrintMaterials () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.printMaterials || languageFallback('Print Materials')
    return message
  },
  /**
   * Label for action button Save all
   * @return {string}
   * */
  getActionButtonSaveAll () {
    const currentLanguage = getCurrentLanguageStrings()
    const message = currentLanguage?.action?.saveAll || languageFallback('Save All')
    return message
  }
}

export const builtStrings = {

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @param {string} dateString
   * @return {string}
   */
  getPageStatusContentDateMomentFormat ({ dateString }) {
    try {
      const currentLanguage = getCurrentLanguageStrings()
      const format = currentLanguage?.pageStatus?.contentDateMomentFormat
      const utcMoment = moment.utc(dateString, 'YYYY-MM-DD HH:mm:ss')
      if (!utcMoment.isValid()) {
        // Fall back to original value
        return dateString
      }
      let formattedDate = utcMoment.locale(getCurrentLanguageCode()).format(format || 'YYYY-MM-DD')
      if (!format) {
        formattedDate = languageFallback(formattedDate)
      }
      return formattedDate
    } catch (error) {
      console.error({ _: 'Could not format response date', error })
      return dateString
    }
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @param {Moment} dateMoment
   * @return {string}
   */
  getPageStatusTopBarDateMomentFormat ({ dateMoment }) {
    const currentLanguage = getCurrentLanguageStrings()
    const format = currentLanguage?.pageStatus?.topBarDateMomentFormat
    let formattedDate = dateMoment.locale(getCurrentLanguageCode()).format(format || 'dddd.DD.MM.YYYY')
    if (!format) {
      formattedDate = languageFallback(formattedDate)
    }
    return formattedDate
  },

  /**
   * TODO: @anyone: Write a description of where this string is used.
   * @param {Moment} dateMoment
   * @return {string}
   */
  getPageStockTopBarDateMomentFormat ({ dateMoment }) {
    const currentLanguage = getCurrentLanguageStrings()
    const format = currentLanguage?.pageStock?.topBarDateMomentFormat
    let formattedDate = dateMoment.locale(getCurrentLanguageCode()).format(format || 'dddd.DD.MM.YYYY')
    if (!format) {
      formattedDate = languageFallback(formattedDate)
    }
    return formattedDate
  }

}
