import axios from 'axios'
import { CREATE, RETRIEVE } from '@/constants/store-actions'

function getItemByIdApi (url, id) {
  const endpoint = `${url}/${id}`
  const requestPromise = axios.get(endpoint)
    .catch((error) => {
      console.error({ _: 'Get Notification By ID failed', url, error })
      throw error
    })
  return requestPromise
}

export default function createSingleItemCrudModule ({ endpoint, mapper, actions }) {
  const actualMapper = mapper ?? (item => item)
  const allowedActions = [RETRIEVE, CREATE] // TODO: add more actions e.g. update, delete, create
  // check if actions contains only allowed actions
  if (actions && !actions.every(action => allowedActions.includes(action))) {
    throw new Error('Invalid actions provided')
  }

  return {
    state: {
      item: {},
      form: {},
      formError: {},
      loading: false,
      created: false
    },
    getters: {
      item (state) {
        return state.item
      },
      loading (state) {
        return state.loading
      },
      created (state) {
        return state.created
      },
      formError (state) {
        return state.formError
      },
      form (state) {
        return state.form
      }
    },
    mutations: {
      setItem (state, data) {
        state.item = actualMapper(data)
      },
      setForm (state, data) {
        state.form = data
      },
      setFormError (state, error) {
        state.formError = error
      },
      setLoading (state, loading) {
        state.loading = loading
      },
      setCreated (state, created) {
        state.created = created
      },
      reset (state) {
        state.form = {}
        state.loading = false
        state.created = false
      },
      updateForm (state, { key, value }) {
        state.form[key] = value
      }
    },
    actions: {
      async retrieveItem ({ commit }, id) {
        if (!actions?.includes(RETRIEVE)) {
          throw new Error('retrieve action not registered for this store')
        }
        commit('setLoading', true)
        const requestPromise = getItemByIdApi(endpoint, id)
          .then((response) => {
            const data = response.data
            commit('setItem', data)
          })
          .catch((error) => {
            commit('setErrorMessage', error?.response?.data?.message ?? error, { root: true })
            console.error({ _: 'Get Item failed', error })
          })
          .finally(() => {
            commit('setLoading', false)
          })
        return requestPromise
      },
      async createItem ({ commit }, requestBody) {
        if (!actions?.includes(CREATE)) {
          throw new Error('create action not registered for this store')
        }
        commit('setLoading', true)
        const requestPromise = axios.post(endpoint, requestBody)
          .then((response) => {
            commit('setCreated', true)
          })
          .catch((error) => {
            commit('setErrorMessage', error?.response?.data?.message ?? error, { root: true })
            console.error({ _: 'Create Item failed', error })
          })
          .finally(() => {
            commit('reset')
          })
        return requestPromise
      }
    }
  }
}
