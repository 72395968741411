<template>
  <b-nav-item class="align-items-center exclamation-icon" v-if="importantMessages.length > 0 && importantMessages[0]" @click="toggleToast">
    <i class="icon-info-circle text-danger"></i>
    <b-toast id="important-message-toast-id" v-if="showToast"  :title="messages.getLabelBroadcastMessageTitleAlert()" toaster="b-toaster-top-full" variant="danger" solid no-auto-hide v-model="showToast">
      <ul>
        <li v-for="(message, index) in importantMessages" :key="index">{{ message }}</li>
      </ul>
    </b-toast>
  </b-nav-item>
</template>

<script>
import { messages } from '@/utils/strings'
export default {
  name: 'ImportantMessageAlert',
  data () {
    return {
      messages,
      showToast: false
    }
  },
  computed: {
    importantMessages () {
      const messages = this.$store.state.profile.importantMessages
      if (messages.length === 1 && messages[0] === '') {
        return []
      }
      return messages
    }
  },
  methods: {
    toggleToast () {
      this.showToast = !this.showToast
    }
  }
}
</script>

<style scoped lang="scss">
.exclamation-icon {
  font-size: 1.3rem;
  transform: rotate(180deg);
}
</style>
