import moment from 'moment'
import createPaginationModule from '../generic/pagination'
import createSingleItemCrudModule from '../generic/single-item-crud'
import { putSmsNotificationMarkAsReadApi } from './logistics-apis'
import { NOTIFICATIONS_SMS_SUGGESTIONS, NOTIFICATION_SMS_ALLOWED_SEARCH_INPUTS } from './search-inputs'
import { RETRIEVE } from '@/constants/store-actions'

const endpoint = '/user_api/v1/sms_notifications'
const url = (
  process.env.VUE_APP_USER_API_ENDPOINT +
  endpoint
)

function parseSMSItems (item) {
  return {
    user_email: item.user_email,
    phone_number: item.phone_number,
    plant: item.plant,
    planner_group: item.planner_group,
    storage_location: item.storage_location,
    work_center: item.work_center,
    message: item.message,
    created: moment(item.created).format('lll')
  }
}

export default {
  namespaced: true,
  modules: {
    smsPagination: createPaginationModule(
      {
        endpoint: url,
        mapper: parseSMSItems
      }),
    smsCrud: createSingleItemCrudModule(
      {
        endpoint: url,
        actions: [RETRIEVE]
      })
  },
  state: {
    searchSuggestionsList: NOTIFICATIONS_SMS_SUGGESTIONS,
    allowedSearchInputs: NOTIFICATION_SMS_ALLOWED_SEARCH_INPUTS
  },
  getters: {
    searchSuggestionsList (state) {
      return state.searchSuggestionsList
    }
  },
  actions: {
    async markAsRead ({ commit }, id) {
      const requestPromise = putSmsNotificationMarkAsReadApi(id)
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          console.error({ _: 'Mark SMS Notification As Read failed', error })
        })
      return requestPromise
    }
  }
}
