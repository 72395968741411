export const MY_STOCK_COUNT = 'my_stock_count'
export const MY_MATERIAL_COUNT = 'my_material_count'
export const MY_MATERIAL = 'my_material'

export const ALL_TYPE_OPTIONS = [MY_STOCK_COUNT, MY_MATERIAL_COUNT]

export const SINGLE = 'single'
export const WALL_TO_WALL = 'wall_to_wall'

export const PLANT = 'plant'
export const STORAGE_LOCATION = 'storage_location'
export const MECHANIC_STORAGE_LOCATION = 'mechanic_storage_location'
export const WORK_CENTER = 'work_center'
export const MECHANIC = 'mechanic'
export const CREATED_BY = 'created_by'
export const TYPE = 'type'
export const CONDUCTED_BY = 'conducted_by'
export const CREATED = 'created'
export const SHOULD_BE_DONE_AT = 'should_be_done_at'
export const STATUS = 'status'
export const QUANTITY = 'quantity'

export const CANCELLED = 'cancelled'
export const COMPLETED = 'completed'
export const ERROR = 'error'
export const IN_PROGRESS = 'in progress'
export const INITIATED = 'initiated'
export const RECOUNT = 'recount'
export const SUBMITTED = 'submitted'

export const ASC = 'asc'
export const DESC = 'desc'

export const ASCENDING = 'Ascending'
export const DESCENDING = 'Descending'
