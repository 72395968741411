import { validateDate, validateMobileInSearch } from '@/utils/validators'
import { finnishDateStringToIsoDateString } from '@/utils/date-mapper'
import { removeSpecialCharacterPhoneNumbers } from '@/utils/string-manipulation'
import { searchInput } from '@/utils/format-search-string'
import {
  PLANT, STORAGE_LOCATION, EQUIPMENT_CODE, EQUIPMENT_DESCRIPTION,
  WORK_CENTER, PLANNER_GROUP, TRACKING_NUMBER, PURCHASE_ORDER, SALES_ORDER,
  DELIVERY_ORDER, SERVICE_ORDER, MATERIAL_CODE, MATERIAL_DESCRIPTION, DELIVERY_DATE, EMAIL, PHONE_NUMBER
} from '@/constants/notifications'
/**
 * the SEARCH_NOTIFICATION_AUTOCOMPLETE_INPUTS array contains all the search inputs that will be used as suggestions in the search bar
 */
const SEARCH_NOTIFICATION_AUTOCOMPLETE_INPUTS = [
  PLANT,
  STORAGE_LOCATION,
  EQUIPMENT_CODE,
  EQUIPMENT_DESCRIPTION,
  WORK_CENTER,
  PLANNER_GROUP,
  TRACKING_NUMBER,
  PURCHASE_ORDER,
  SALES_ORDER,
  DELIVERY_ORDER,
  SERVICE_ORDER,
  MATERIAL_CODE,
  MATERIAL_DESCRIPTION,
  DELIVERY_DATE,
  EMAIL
]

/**
 * COMMON_NOTIFICATION_SEARCH_INPUTS contains all the search inputs
 * the key is the name of the input provided to the backend search api
 * the value is an object that contains the following:
 * 1- validator: a function that takes a string and returns a boolean indicating whether the string is valid or not
 * 2- mapper: a function that takes a string and returns another string
 * 3- items: an array of strings that will be used to match the input with the key
 */

const COMMON_NOTIFICATION_SEARCH_INPUTS = {
  purchase_order: searchInput(['purchase order', 'purchaseorder', 'po']),
  delivery_order: searchInput(['delivery order', 'deliveryorder', 'do']),
  sales_order: searchInput(['sales order', 'salesorder']),
  service_order: searchInput(['service order', 'serviceorder', 'so']),
  storage_location: searchInput(['sloc', 'storage location', 'storagelocation']),
  tracking_number: searchInput(['tracking number', 'trackingnumber', 'tn']),
  plant: searchInput(['plant', 'plants']),
  planner_group: searchInput(['planner group', 'plannergroup']),
  equipment_code: searchInput(['equipment', 'equipment code', 'equipmentcode', 'ec']),
  equipment_description: searchInput(['equipment description', 'equipmentdescription', 'eqd']),
  work_center: searchInput(['work center', 'workcenter', 'wc']),
  user_email: searchInput(['user email', 'ue', 'email']),
  delivery_order_doc_number: searchInput(['delivery', 'dl']),
  material_code: searchInput(['material', 'mat', 'material code', 'materialcode', 'mc']),
  material_description: searchInput(['material description', 'materialdescription', 'matd']),
  delivery_date: searchInput(['delivery date', 'dd'], validateDate, finnishDateStringToIsoDateString)
}

export const NOTIFICATION_EMAIL_ALLOWED_SEARCH_INPUTS = {
  ...COMMON_NOTIFICATION_SEARCH_INPUTS
}
export const NOTIFICATIONS_EMAIL_SUGGESTIONS = [
  ...SEARCH_NOTIFICATION_AUTOCOMPLETE_INPUTS
].sort()
export const NOTIFICATION_SMS_ALLOWED_SEARCH_INPUTS = {
  ...COMMON_NOTIFICATION_SEARCH_INPUTS,
  phone_number: searchInput(['phone number', 'phonenumber', 'pn'], validateMobileInSearch, removeSpecialCharacterPhoneNumbers)
}
export const NOTIFICATIONS_SMS_SUGGESTIONS = [
  ...SEARCH_NOTIFICATION_AUTOCOMPLETE_INPUTS,
  PHONE_NUMBER
].sort()
export const NOTIFICATION_PUSH_NOTIFICATION_ALLOWED_SEARCH_INPUTS = {
  ...COMMON_NOTIFICATION_SEARCH_INPUTS
}
export const NOTIFICATIONS_PUSH_NOTIFICATION_SUGGESTIONS = [
  ...SEARCH_NOTIFICATION_AUTOCOMPLETE_INPUTS
].sort()
