import store from '@/store/index'

export function wrapAxiosError (err, reject) {
  console.error({ err })
  let message = err.message
  if (err.response) {
    message = err.response.data.message
  }
  store.commit('setErrorMessage', message)
  reject(new Error(message))
}

export function removeUppercaseEmails (emails) {
  // Remove email addresses that contain any upper case letters.
  // In our case the whole email is written with only upper case letters.
  const uniqueEmails = []
  for (const email of emails) {
    if (isLowerCase(email)) {
      uniqueEmails.push(email)
    }
  }
  return uniqueEmails
}

// Returns true if whole email is written with lower case letters.
const isLowerCase = email => email === email.toLowerCase()
