/**
 * Takes a Finnish date string (format: `"DD.MM.YYYY"`, example:
 * `"12.04.2021"`), and returns an ISO date string (format:
 * `"YYYY-MM-DD"`, example: `"2021-04-12"`), or empty string if the Finnish
 * date string is invalid.
 *
 * Note: Format: `"DD.MM.YYYY"` is determined by the Backend and
 * format: `"YYYY-MM-DD"` is determined by `<b-calendar>`, so neither
 * should be localized.
 * @param {*} date string in Finnish format
 * @returns date string in ISO format
 */
export function finnishDateStringToIsoDateString (value) {
  // eslint-disable-next-line
  const dateRegex = new RegExp(
    '^\\s*([0-9]{2}[\\/\\.\\\\-]?[0-9]{2}[\\/\\.\\\\-]?[0-9]{4})\\s*$'
  )
  if (dateRegex.test(value)) {
    return value?.split(/[\\/\\.\\\\-]/).reverse().join('-')
  } else {
    return value
  }
}
