import axios from 'axios'
import {
  INITIATED,
  MY_MATERIAL_COUNT
} from '@/constants/stock-count'
import { materialCountSearchHistory } from '../utils/search-history'
import { messages } from '@/utils/strings'
import { IN_PROGRESS } from '../constants/stock-count'

const DEFAULT_MAX_RESULTS = 20
const DEFAULT_PARAMETERS = 'maxResults=20&page=1'

function listMaterialCountItems (queryParameters) {
  const parameters = ['query', 'maxResults', 'page']
  parameters.forEach(parameter => {
    if (queryParameters[parameter] === undefined) {
      throw Error(`Missing parameter ${parameter} in listStockCountItems`)
    }
  })
  const { stockCountId, ...params } = queryParameters
  const endpoint = `/api/v2/my_material_count?stockCountId=${stockCountId}`
  const url = process.env.VUE_APP_STOCK_API_ENDPOINT + endpoint
  const requestPromise = axios.get(url, { params })
    .catch(error => {
      console.error({ _: 'listMaterialCountItems failed', queryParameters, url, error })
      throw error
    })
  return requestPromise
}

/**
 * Special method for getting all materials in Stock Count for printing.
 * @param {object} queryParameters
 * @param {string} queryParameters.stockCountId
 * @param {string} queryParameters.orderByColumn which column is used with ordering
 * @param {string} queryParameters.sortDirection sort direction, asc or desc
 * @returns List of all materials in the Stock Count
 */
function listMaterialCountItemsForPrinting (queryParameters) {
  const { stockCountId, ...params } = queryParameters
  const endpoint = `/api/v2/relationship/print_materials?stockCountId=${stockCountId}`
  const url = process.env.VUE_APP_STOCK_API_ENDPOINT + endpoint
  const requestPromise = axios.get(url, { params })
    .catch(error => {
      console.error({ _: 'listMaterialCountItems failed', queryParameters, url, error })
      throw error
    })
  return requestPromise
}

function editMaterialCountQuantities (parameters) {
  const url = process.env.VUE_APP_STOCK_API_ENDPOINT + `/api/v2/my_material_count?${DEFAULT_PARAMETERS}`
  const requestPromise = axios.put(url, { modifiedMaterials: parameters })
    .catch(error => {
      console.error({ _: 'editMaterialCountQuantities failed', parameters, url, error })
      throw error
    })
  return requestPromise
}

function addMaterialCount (parameters) {
  const { stockCountId, materialCode } = parameters
  const url = process.env.VUE_APP_STOCK_API_ENDPOINT + '/api/v2/my_material_count'
  const requestPromise = axios.post(url, { stockCountId, materialCode })
    .catch(error => {
      console.error({ _: 'addMaterialCount failed', parameters, url, error })
      throw error
    })
  return requestPromise
}

function deleteMaterialCount (parameters) {
  const { materialCountId } = parameters
  const url = process.env.VUE_APP_STOCK_API_ENDPOINT + `/api/v2/my_material_count?materialCountId=${materialCountId}`
  const requestPromise = axios.delete(url)
    .catch(error => {
      console.error({ _: 'deleteMaterialCount failed', parameters, url, error })
      throw error
    })
  return requestPromise
}

function parseBackendMaterialCountItems (item) {
  return {
    id: item.id,
    material_code: item.material_code,
    material_description_english: item.material_description_english,
    quantity: item.quantity,
    unit: item.unit
  }
}

export default {
  namespaced: true,
  state: {
    firstFetchDone: false,
    materialCodes: [],
    materialCountItems: [],
    materials: [],
    materialsForPrinting: [],
    ongoingQuery: false,
    orderByColumn: 'quantity',
    page: 0,
    query: [],
    searchParts: [],
    sortDirection: 'asc', // b-table sortDesc false means ascending order
    stockCount: {},
    stockCountId: null,
    success: {
      message: '',
      messageTimeout: 0
    },
    total: 0,
    type: MY_MATERIAL_COUNT,
    userSearchOptions: {
      maxResults: DEFAULT_MAX_RESULTS
    }
  },
  getters: {
    /**
     * Has there been at least one call to Stock Count API for listing stock items
     *
     * This helps to determine when the page is loaded.
     *
     * @param {Object} state - Vuex state for getters.
     * @returns {boolean} - True after first request to Stock API is finished.
     */
    firstFetchDone: (state) => {
      return state.firstFetchDone
    },
    /**
     * Indicates if front-end is currently calling Stock API to list stock items
     *
     * @param {Object} state - Vuex state for getters.
     * @returns {boolean} - True if request has been made but response has not arrived.
     */
    ongoingQuery: (state) => {
      return state.ongoingQuery
    },
    searchParts: (state) => {
      return state.searchParts
    },
    allMaterialCountItems: (state) => {
      return state.materialCountItems
    },
    materialCountItems: (state) => page => {
      return state.materialCountItems[page]?.materialCountItems ?? []
    },
    mobileMaterialCountItems: (state) => {
      let mobileItems = []
      for (const itemPage of state.materialCountItems) {
        mobileItems = [...mobileItems, ...itemPage.materialCountItems]
      }
      return mobileItems
    },
    page: (state) => {
      return state.page
    },
    lastPage: (state) => {
      if (state.materialCountItems.length === 0) {
        return null
      }
      const lastPage = state.materialCountItems?.[state.materialCountItems.length - 1]
      return lastPage
    },
    hasNext: (state, getters) => {
      const currentPage = state.materialCountItems[getters.page]
      if (currentPage) {
        return currentPage.hasNext
      }
      return false
    },
    hasPrev: (state, getters) => {
      const currentPage = state.materialCountItems[getters.page]
      if (currentPage) {
        return currentPage.hasPrev
      }
      return false
    },
    total: (state) => {
      // total amount of material count items. Each page contains the total
      return state.total
    },
    materialCountItemsLength: (state, getters) => {
      return state.materialCountItems[getters.page]?.total
    },
    sortDirection: (state) => {
      if (state.sortDirection === 'desc') { // false is ascending and true is descending order
        return true
      }
      return false
    },
    orderByColumn: (state) => {
      return state.orderByColumn
    },
    materialsToPrint: (state) => {
      return state.materialsForPrinting
    },
    stockCount: (state) => {
      return state.stockCount
    },
    stockCountId: (state) => {
      return state.stockCountId
    }
  },
  mutations: {
    setSuccessMessage (state, message) {
      state.success.message = message
      state.success.messageTimeOut = 9
    },
    setOrderByColumn (state, value) {
      state.orderByColumn = value
    },
    setSortDirection (state, value) {
      if (value) { // false is ascending and true is descending order
        state.sortDirection = 'desc'
      } else {
        state.sortDirection = 'asc'
      }
    },
    clearMaterialCount (state) {
      state.page = 0
      state.firstFetchDone = false
      state.materialCountItems = []
    },
    addMaterialCountPage (state, { queryParameters, response }) {
      const matchingQueryPages = state.materialCountItems.filter(page => page.query === queryParameters.query)
        .filter(page => page.type === queryParameters.type)
        .filter(page => page.maxResults === queryParameters.maxResults)
      if (matchingQueryPages > 0) {
        const matchingPage = matchingQueryPages.find(page => page.page === queryParameters.page)
        if (matchingPage) {
          throw Error('addStockCountPage tried to add a page that has already been fetched. Cache mismatch.')
        }
      }
      const newPage = {
        index: state.materialCountItems.length,
        query: queryParameters.query,
        type: queryParameters.type,
        page: response.data.page,
        pages: response.data.pages,
        hasNext: response.data.has_next,
        hasPrev: response.data.has_prev,
        total: response.data.total,
        materialCountItems: response.data.material_count_items.map(parseBackendMaterialCountItems)
      }
      state.materialCountItems.push(newPage)
      state.page = newPage.index
      state.total = newPage.total
    },
    clearMaterialCountItems (state) {
      state.materialCountItems = []
    },
    startQuery (state) {
      state.ongoingQuery = true
    },
    finishQuery (state) {
      state.firstFetchDone = true
      state.ongoingQuery = false
    },
    setPage (state, page) {
      if (page === undefined || page === null) {
        throw Error('Missing required parameter "page" in setPage')
      }
      if (page !== parseInt(page)) {
        // Rudimentary value check
        throw Error(`Invalid value ${page} for page parameter in setPage`)
      }
      if (page > state.materialCountItems.length) {
        throw Error(`Provided page value ${page} is out of bound for last page index ${state.materialCountItems.length}`)
      }
      state.page = page
    },
    editMaterialCountQuantities (state, { materialCountId, quantity }) {
      state.materialCountItems[state.page].materialCountItems = state.materialCountItems[state.page].materialCountItems.map(item => {
        if (item.id === materialCountId) {
          return { ...item, quantity }
        }
        return item
      })
    },
    removeMaterialCountFromMaterialCountsList (state, { materialCountId }) {
      state.materialCountItems[state.page].materialCountItems = state.materialCountItems[state.page].materialCountItems.filter(item => {
        return item.id !== materialCountId
      })
    },
    addMaterialsForPrinting (state, { response }) {
      state.materialsForPrinting = response.data
    },
    clearMaterialsToPrint (state) {
      state.materialsForPrinting = []
    },
    setSearchParts (state, searchParts) {
      state.searchParts = searchParts ?? []
    },
    setStockCountId (state, stockCountId) {
      state.stockCountId = stockCountId
    },
    setStockCount (state, stockCount) {
      state.stockCount = stockCount
    }
  },
  actions: {
    /**
     * Fetch the next page using the page information from last response
     *
     * If there is at least one page and that page hasNext is true (meaning that there are more
     * pages to fetch), then we can fetch next page and continue the pagination.
     *
     * @param {ActionContext} [vuexContext]
     * @returns {Promise} - Raw Tracking Status response from the backend
     *  (passed by fetchPage action)
     */
    fetchPage ({ commit }, queryParameters) {
      commit('startQuery')
      const requestPromise = listMaterialCountItems(queryParameters)
        .then(response => {
          commit('addMaterialCountPage', { queryParameters, response })
          return response
        })
        .catch(error => {
          commit('setErrorMessage', error?.response?.data ?? error, { root: true })
        })
        .finally(() => {
          commit('finishQuery')
        })
      return requestPromise
    },
    fetchNextPage ({ state, dispatch, getters }, maxResults) {
      let queryParameters = {}
      if (getters.lastPage) {
        queryParameters = {
          query: getters.lastPage.query ?? [],
          page: getters.lastPage.page + 1,
          maxResults: maxResults ?? getters.lastPage.maxResults ?? DEFAULT_MAX_RESULTS,
          stockCountId: state.stockCountId,
          orderByColumn: state.orderByColumn,
          sortDirection: state.sortDirection
        }
      } else {
        queryParameters = {
          query: state.searchParts ?? [],
          page: state.page + 1,
          maxResults: maxResults ?? DEFAULT_MAX_RESULTS,
          stockCountId: state.stockCountId,
          orderByColumn: state.orderByColumn,
          sortDirection: state.sortDirection
        }
      }
      return dispatch('fetchPage', queryParameters)
    },
    editMaterialCountQuantities ({ state, commit }, parameters) {
      commit('startQuery')
      const requestPromise = editMaterialCountQuantities(parameters)
        .then(response => {
          if (state.stockCount.status.value.toLowerCase() === INITIATED) {
            state.stockCount.status = { name: messages.getLabelInProgress(), value: IN_PROGRESS } // local change
            commit('stockCount/changeStockCountStatus', { stockCountId: state.stockCountId, status: { name: messages.getLabelInProgress(), value: IN_PROGRESS } }, { root: true }) // array change
          }
          return response
        })
        .catch(error => {
          commit('setErrorMessage', error?.response?.data ?? error, { root: true })
        })
        .finally(() => {
          commit('finishQuery')
        })
      return requestPromise
    },
    addMaterialCount ({ commit }, parameters) {
      commit('startQuery')
      const requestPromise = addMaterialCount(parameters)
        .then(response => {
          // Nice way would be to add the material to list here but that would require a lot
          // of work because of pagination.
          return response
        })
        .catch(error => {
          commit('setErrorMessage', error?.response?.data ?? error, { root: true })
        })
        .finally(() => {
          commit('finishQuery')
        })
      return requestPromise
    },
    deleteMaterialCount ({ commit }, parameters) {
      commit('startQuery')
      const requestPromise = deleteMaterialCount(parameters)
        .then(response => {
          // Removing item will screw pagination but usually (single count) the list of items
          // should be short so there is no pagination.
          commit('removeMaterialCountFromMaterialCountsList', parameters)
          return response
        })
        .catch(error => {
          commit('setErrorMessage', error?.response?.data ?? error, { root: true })
        })
        .finally(() => {
          commit('finishQuery')
        })
      return requestPromise
    },
    fetchMaterialsForPrinting ({ commit }, queryParameters) {
      commit('startQuery')
      const requestPromise = listMaterialCountItemsForPrinting(queryParameters)
        .then(response => {
          commit('addMaterialsForPrinting', { queryParameters, response })
          return response
        })
        .catch(error => {
          commit('setErrorMessage', error?.response?.data ?? error, { root: true })
        })
        .finally(() => {
          commit('finishQuery')
        })
      return requestPromise
    },
    /**
     * @param {ActionContext} [vuexContext]
     * @param {Object} queryParameters
     * @param {string[]|null} [queryParameters.query] - Free text search strings. If empty or null,
     *   the stock items are listed by my_stock, my_team_stock and my_plant_stock
     * @param {number} [queryParameters.maxResults] Limit how many items are at most in the response.
     *   Default value is using userSearchOptions state.
     * @param {boolean} [queryParameters.saveToSearchHistory=true] - Save free text used for search to local storage.
     * @returns {Promise} Raw MaterialCount response from the backend
     */
    search (
      { state, getters, commit, dispatch },
      { query, maxResults, saveToSearchHistory = true }
    ) {
      if (getters.ongoingQuery) {
        // When calling this action check that there is no ongoing query.
        // TODO: There should be something to reset ongoingQuery if something goes
        // wrong. Like the response never arrives. Add timeout check or something
        // to the axios call.
        console.error({
          _: 'materialCount/search action called when query was ongoing',
          query,
          maxResults
        })
        throw Error('Query is still in progress!')
      }
      const queryParameters = {
        query,
        page: 1, // search returns first page
        stockCountId: state.stockCountId,
        orderByColumn: state.orderByColumn,
        sortDirection: state.sortDirection,
        maxResults: maxResults ?? state.userSearchOptions.maxResults
      }
      commit('clearMaterialCountItems')
      if (saveToSearchHistory && queryParameters.query?.length) {
        materialCountSearchHistory.add(queryParameters.query.join(' '))
      }
      // Store the search free text for showing indicator that the results are
      // from a search. Query can be empty.
      commit('setSearchParts', queryParameters.query)
      return dispatch('fetchPage', queryParameters)
    }
  }
}
