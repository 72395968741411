/**
 * Contains locale options selectable in "User Profile" page.
 */

import deepFreeze from '@/utils/deep-freeze'

const locales = {
  'cs-CZ': 'Čeština (cs-CZ)',
  'de-DE': 'Deutsch (de-DE)',
  'en-US': 'English (en-US)',
  'es-ES': 'Español (es-ES)',
  'fi-FI': 'Suomi (fi-FI)',
  'fr-FR': 'Français (fr-FR)',
  'it-IT': 'Italiano (it-IT)',
  'pt-PT': 'Português (pt-PT)',
  'sv-SE': 'Svenska (sv-SE)'
}
deepFreeze(locales)
export default locales

export const defaultLocale = 'en-US'

/**
 * Ensures that a locale value is valid, by returning `defaultLocale` if it is
 * not.
 *
 * This is vital because an invalid locale breaks the localization system.
 */
export function ensureValidLocale (locale) {
  if (typeof locales[locale] !== 'string') {
    return defaultLocale
  }
  return locale
}
