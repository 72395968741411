/* global firebase */
import { mapGetters } from 'vuex'
import { NOTIFICATION_SETTINGS } from '@/constants/store-names'
import { DEVICE_ID } from '@/constants/system-info'

export const signOutMixin = {
  methods: {
    signOut () {
      if (this.inAllowedNotificationPlants) {
        this.$store.commit(`${NOTIFICATION_SETTINGS}/updateDeviceById`, {
          deviceId: localStorage.getItem(DEVICE_ID),
          newDeviceInfo: {
            isLoggedIn: false
          }
        })
      }
      this.$store.dispatch(`${NOTIFICATION_SETTINGS}/updateNotificationSettingsNoLoading`, this.$store.state.profile.uid).then(() => {
        this.$store.commit('setInitialState')
        firebase.auth().signOut()
      })
    }
  },
  computed: {
    ...mapGetters({
      inAllowedNotificationPlants: `${NOTIFICATION_SETTINGS}/inAllowedNotificationPlants`
    })
  }
}
