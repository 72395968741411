export const STATUS_ALL = 'all'
export const STATUS_ARRIVED = 'arrived'
export const STATUS_INCOMING = 'incoming'
export const ALL_STATUS_OPTIONS = [STATUS_ALL, STATUS_ARRIVED, STATUS_INCOMING]

export const PO_GR_PENDING_TO_BE_DELIVERED = 'PO_GR_PendingToBeDelivered'
export const DELIVERED = 'Delivered'
export const DELIVERY_FAILED = 'Delivery Failed'
export const ON_THE_WAY = 'OnTheWay'
export const TO_BE_PICKED_UP = 'ToBePickedUp'
export const INBOUND = 'Inbound'
export const OUTBOUND = 'Outbound'
export const AT_ACCESS_POINT = 'At access point'

export const PURCHASE_ORDER_GOODS_RECEIPT_ITEM = 'PurchaseOrderGoodsReceiptItem'
export const PURCHASE_ORDER_CONFIRMATION_ITEM = 'PurchaseOrderConfirmationItem'
export const AB = 'AB'
export const LA = 'LA'

// The following Customer infomation should NOT be hard coded nor stored at client side.
// Jira task related to this issue: https://verkkoaps.atlassian.net/browse/CPL-698
export const APPROVER_EMAIL = 'askit@kone.com'

// One customer can have multiple domains
export const CUSTOMER_DOMAINS = ['kone.com', 'gse.fi', 'gafe.fi', 'cloudpoint.fi', 'gtest.fi']

// CPL-937 Kone requested that mobile should load more items at once and it was decided that 60 items is the amount.
export const MOBILE_MAX_RESULTS = 60
export const MAX_RESULTS = 20

export const ASC = 'asc'
export const DESC = 'desc'

export const GT = '>'
export const LT = '<'
export const GTE = '>='
export const LTE = '<='
export const EQ = '='
export const NE = '!='

export const GT_EQUIVALENT = '__gt='
export const LT_EQUIVALENT = '__lt='
export const GTE_EQUIVALENT = '__gte='
export const LTE_EQUIVALENT = '__lte='
export const EQ_EQUIVALENT = '='
export const NE_EQUIVALENT = '__ne'
