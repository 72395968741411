<template>
  <b-navbar
    fixed="bottom"
    class="bottom-bar"
    :style="{ height: height }"
  >
    <b-navbar-nav
      class="w-100"
      justified
    >
      <b-nav-item
        v-for="(item, key) in items"
        :key="key"
        :to="item.to"
      >
        <i
          class="icon"
          :class="[item.icon, { 'selected-item': selectedItem(item.to.name) }]"
        />
      </b-nav-item>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import { navMixin } from './nav-mixin'

export default {
  name: 'BottomBar',
  props: {
    items: {
      required: true,
      type: Array
    },
    height: {
      required: true,
      type: String
    }
  },
  mixins: [navMixin]
}
</script>

<style scoped lang="scss">
.bottom-bar {
  border: 1px solid $secondary;
  background-color: white;
  display: flex;
}
.icon {
  font-size: 26px;
  color: $gray-500;
}
.selected-item {
  color: $blue-400;
}
</style>
