<template>
  <b-alert
    v-model="isError"
    variant="danger"
    dismissible
    class="m-4 fixed-bottom put-on-top"
    fade
  >
    {{ $store.state.error.message }}
  </b-alert>
</template>

<script>
export default {
  name: 'ErrorAlert',
  computed: {
    isError: {
      get: function () {
        return this.$store.state.error.messageTimeout
      },
      set: function (value) {
        this.$store.commit('closeErrorMessage', value)
      }
    }
  }
}
</script>

<style scoped>
.put-on-top {
  z-index: 1050;
}
</style>
