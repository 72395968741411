/**
 * Escapes the special `$` syntax of the second parameter of `replace` and
 * `replaceAll` string methods.
 *
 * Should be used when a user-defined string is used as a replacement.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replace#specifying_a_string_as_a_parameter
 * @param { string } replacement - A replacement string
 * @returns { string } `$`-escaped replacement string
 */
export default function escapeReplacementString (replacement) {
  return replacement.replaceAll('$', '$$$$')
}
