import moment from 'moment'
import { getNotificationDropDownListApi } from './logistics-apis'

function mapResponseToNotificationsDropDownList (response) {
  const notificationsDropDownList = response.data.notifications.map((notification) => {
    const createdDate = moment(notification.created)
    const isMoreThanOneDayOld = moment().diff(createdDate, 'days') > 0
    const formattedDate = isMoreThanOneDayOld ? createdDate.format('lll') : createdDate.fromNow()
    return {
      id: notification.id,
      type: notification.notification_type,
      text: notification.notification_text,
      createdDate: formattedDate
    }
  })
  return notificationsDropDownList
}
export default {
  namespaced: true,
  state: {
    notificationsDropDownList: []
  },
  getters: {
    notificationsDropDownList: (state) => {
      return state.notificationsDropDownList
    }
  },
  mutations: {
    setNotificationsDropDownList (state, response) {
      state.notificationsDropDownList = mapResponseToNotificationsDropDownList(response.response)
    },
    removeNotificationById (state, id) {
      state.notificationsDropDownList = state.notificationsDropDownList.filter((notification) => {
        return notification.id !== id
      })
    }
  },
  actions: {
    async getNotificationsDropDownList ({ commit }) {
      const requestPromise = getNotificationDropDownListApi()
        .then((response) => {
          commit('setNotificationsDropDownList', { response })
        })
        .catch((error) => {
          console.error({ _: 'Get Notification Preferences failed', error })
        })
      return requestPromise
    },
    async removeNotificationById ({ commit }, id) {
      commit('removeNotificationById', id)
    }
  }
}
