<template>
  <div>
    <b-dropdown
      class="scrollable"
      id="notification-dropdown"
      size="lg"
      variant="link"
      toggle-class="text-decoration-none"
      no-caret
      right
      text="Right align"
      :disabled="notifications.length === 0"
    >
      <!-- Dropdown trigger button -->
      <template #button-content>
        <b-badge v-if=" notifications.length > 0" variant="danger" >{{ notifications.length }}</b-badge>
        <i class="icon-alarm" :class="{'text-white': isMobile()}"></i>
      </template>
      <!-- Dropdown menu items -->
      <!-- Dropdown content goes here -->
      <b-dropdown-item v-for="(notification, index) in notifications" :key="index">
        <div class="d-flex align-items-start" @click="showModal(notification.id,notification.type)">
          <div class="mr-3">
            <i class="icon-message text-primary" v-if="notification.type==EMAIL_TYPE" style="font-size: 1.35rem;"></i>
            <i class="icon-greeting text-primary" v-if="notification.type==SMS_TYPE" style="font-size: 1.35rem;"></i>
            <i class="icon-earthquake text-primary" v-if="notification.type==PUSH_NOTIFICATION_TYPE" style="font-size: 1.35rem;"></i>
          </div>
          <div class="flex-grow-1">
            <div class="font-weight-bold">{{ notification.text }}</div>
            <div class="text-muted text-right">{{ notification.createdDate }}</div>
          </div>
        </div>
      </b-dropdown-item>
    </b-dropdown>
    <view-email-modal :id="selectedNotificationId" />
    <view-sms-modal :id="selectedNotificationId" />
    <view-push-notification-modal :id="selectedNotificationId" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ViewEmailModal from '../NotificationsView/ViewEmailModal'
import ViewSmsModal from '../NotificationsView/ViewSmsModal'
import ViewPushNotificationModal from '../NotificationsView/ViewPushNotificationModal'
import { NOTIFICATION_DROPDOWN, NOTIFICATION_EMAIL, NOTIFICATION_SMS, NOTIFICATION_PUSH_NOTIFICATION } from '@/constants/store-names'
import { MOBILE_WIDTH } from '@/constants/screen'

const EMAIL_TYPE = 'email'
const SMS_TYPE = 'sms'
const PUSH_NOTIFICATION_TYPE = 'push'
export default {
  name: 'NotificationDropDown',
  components: { ViewEmailModal, ViewSmsModal, ViewPushNotificationModal },
  data () {
    return {
      selectedNotificationId: null,
      EMAIL_TYPE,
      SMS_TYPE,
      PUSH_NOTIFICATION_TYPE
    }
  },
  computed: {
    ...mapGetters({
      notifications: `${NOTIFICATION_DROPDOWN}/notificationsDropDownList`
    })
  },
  created () {
    this.$store.dispatch(`${NOTIFICATION_DROPDOWN}/getNotificationsDropDownList`)
  },
  methods: {
    showModal (id, type) {
      this.$store.dispatch(`${NOTIFICATION_DROPDOWN}/removeNotificationById`, id)
      this.selectedNotificationId = id
      if (type === EMAIL_TYPE) {
        this.$store.dispatch(`${NOTIFICATION_EMAIL}/retrieveItem`, id)
        this.$bvModal.show('showEmailModal')
        this.$store.dispatch(`${NOTIFICATION_EMAIL}/markAsRead`, id)
      } else if (type === SMS_TYPE) {
        this.$store.dispatch(`${NOTIFICATION_SMS}/retrieveItem`, id)
        this.$bvModal.show('showSmsModal')
        this.$store.dispatch(`${NOTIFICATION_SMS}/markAsRead`, id)
      } else if (type === PUSH_NOTIFICATION_TYPE) {
        this.$store.dispatch(`${NOTIFICATION_PUSH_NOTIFICATION}/retrieveItem`, id)
        this.$bvModal.show('showPushNotificationModal')
        this.$store.dispatch(`${NOTIFICATION_PUSH_NOTIFICATION}/markAsRead`, id)
      } else {
        throw new Error('Unsupported notification type')
      }
    },
    isMobile () {
      return this.$screen.width <= MOBILE_WIDTH
    }
  }
}
</script>

<style scoped>
.scrollable /deep/ .dropdown-menu {
  max-height: 18.75rem;
  overflow-y: auto;
}
.btn .badge {
  position: absolute;
  top: 10px;
  right: 10px;
  transform: translate(50%, -50%);
  padding: 0.25em 0.4em;
  font-size: 0.55em;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.375rem;
}
</style>
