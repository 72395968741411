/**
 * This function will return a boolean value indicating whether the two arrays
 * have any common elements.
 * @param {*} array1
 * @param {*} array2
 * @returns boolean
 */
export function hasCommonElement (array1, array2) {
  return array1.some((item) => array2.includes(item))
}
