/**
 * Takes in an array, and returns a new array with only one instance of each
 * value.
 *
 * Note: Remember that separate, but indentical objects are not removed,
 * because they are not the same (as in: they are references to separate
 * objects):
 * ```
 * > {a: 1} === {a: 1}
 * false
 * > [1, 2] === [1, 2]
 * false
 * ```
 *
 * @param {Array} array -- An array with possibly redundant values.
 * @returns {Array} New array with no redundancies.
 * @see https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
 */
export default function uniqueOnly (array) {
  if (!Array.isArray(array)) {
    throw new TypeError(
      'Expect parameter: `array` to be an array, instead it was: ' + array +
      ' (type: ' + typeof array + ').'
    )
  }
  return [...new Set(array)]
}
