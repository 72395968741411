/**
 * Process and enrich responses from project APIs
 *
 * Enriched properties should start with an undescore `_`.
 */
import { messages } from '@/utils/strings'
import {
  getSummaryForOrderRow,
  // calculateReservationQuantitySum,
  deliveryDateIsEta,
  getTrackingStatusStage
} from './tracking-status'

/**
 *
 * @param {array} responseTrackingStatuses - `trackingStatus` array from TrackingStatus resource.
 *   API response from "/parts/v1/users/<uid>/tracking-status":
 *     {
 *       ...
 *       trackingStatus: [ ... ]  <-- this
 *     }
 *  @returns {array} - Copy of the passed array with addional enriched properties.
 */
function enrichTrackingStatusItems (responseTrackingStatuses) {
  const trackingStatuses = JSON.parse(JSON.stringify(responseTrackingStatuses))
  for (const trackingStatus of trackingStatuses) {
    // currently we get item.transportDetails as string for response
    // temporarily we parse this string to the array of transport details objects
    // till we fix on the backend so item.transportDetails will be really array of objects
    // smsTimestamps is also a string with an array of two timestamps.
    trackingStatus.items.forEach(item => {
      if (typeof item.transportDetails === 'string') {
        item.transportDetails = JSON.parse(item.transportDetails)
      }
      if (typeof item.smsTimestamps === 'string') {
        item.smsTimestamps = JSON.parse(item.smsTimestamps)
      }
    })

    // FIXME: Remove to be deprecated properties
    delete trackingStatus.deliveryDateIsEta
    delete trackingStatus.scheduledQuantity
    delete trackingStatus.scheduledQuantityUnit

    trackingStatus._rowType = 'OrderRow' // Redundant, remove later.
    // Child items consists information that is useful to have in order row
    const orderRowSummary = getSummaryForOrderRow(trackingStatus)
    trackingStatus._orderRowSummary = orderRowSummary

    for (const trackingStatusItem of trackingStatus.items || []) {
      // FIXME: Remove to be deprecated properties
      delete trackingStatusItem.deliveryDateIsEta
      delete trackingStatusItem.scheduledQuantity
      delete trackingStatusItem.scheduledQuantityUnit
      // Insert reservation quantity sum wheter the item needs it or not.
      // FIXME: See https://verkkoaps.atlassian.net/browse/CPL-261
      // *NOTE* wrong place, use materialCodeItems trackingStatusItem._reservationQuantitySum = reservationQuantitySum
      trackingStatusItem.deliveryDateIsEta = deliveryDateIsEta(trackingStatusItem)
      trackingStatusItem._stage = getTrackingStatusStage(trackingStatusItem)
      trackingStatusItem._formattedPurchaseOrderInfo = _formatPurchaseOrderInfo(trackingStatusItem)

      // Inject parent level properties to all child items
      trackingStatusItem._serviceOrder = trackingStatus.serviceOrder
      trackingStatusItem._equipmentCode = trackingStatus.equipmentCode
    }

    // Create a list of purchase order strings removing duplicates to display in the order row.
    // End result example "4532640000/10, 4532640000/20"
    trackingStatus._formattedPurchaseOrderRows = [...new Set(trackingStatus.items.map(
      item => item._formattedPurchaseOrderInfo
    ))]
    trackingStatus._formattedPurchaseOrderRows.sort()
  }
  return trackingStatuses
}

/**
 *  Formats Purchase Order realted numbers.
 *
 *  This to help filtering out duplicated end results, but can be used for displaying the value.
 *
 *  Example A:
 *    Input purchaseOrderDocNumber: "00004532640000"
 *    Output: "4532640000"
 *
 *  Example B:
 *    Input purchaseOrderDocNumber: undefined
 *    Output: "?"
 *
 * If purchaseOrderDocNumber is missing (nullish), this will replace it with a single question mark.
 *
 * @param {object} trackingStatusItem
 * @returns String presentation of Purchase Order Document.
 */
function _formatPurchaseOrderInfo (trackingStatusItem) {
  const removeLeadingZeros = string => string.replace(/^0+/, '')
  const trimmedDocNumber = removeLeadingZeros(trackingStatusItem.purchaseOrderDocNumber ?? `[${messages.getLabelNoPurchaseOrder()}]`)
  return `${trimmedDocNumber}`
}

export { enrichTrackingStatusItems }
