import axios from 'axios'
import { wrapAxiosError } from '@/store/helpers'

function parseBackendUapInfoItem (uapInfo) {
  const newUapInfo = {
    shipToPartyCode: uapInfo.shipToPartyCode,
    priorities: uapInfo.uapinfos
  }
  return newUapInfo
}

export default {
  namespaced: true,
  state: {
    uapInfos: []
  },
  getters: {
  },
  mutations: {
    setUapInfos (state, { uapInfos }) {
      state.uapInfos = uapInfos
    }
  },
  actions: {
    getPriorities ({ commit }, { query, countries, maxResults }) {
      return new Promise((resolve, reject) => {
        let url = `${process.env.VUE_APP_USER_API_ENDPOINT}/user_api/v1/tracking/uap/priorities`
        url += '?query=' + query
        for (const i in countries) {
          url += '&countries=' + countries[i]
        }
        maxResults = maxResults || 120
        url += '&maxResults=' + maxResults
        axios.get(url)
          .then((resp) => {
            commit('setUapInfos', { uapInfos: resp.data.items.map(parseBackendUapInfoItem) })
            resolve(resp)
          })
          .catch((err) => wrapAxiosError(err, reject))
      })
    }
  }
}
