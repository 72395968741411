export const STOCK_COUNT = 'StockCount'
export const SMS = 'SMS'
export const EMAIL = 'Email'
export const SENT_FEEDBACK = 'SentFeedback'
export const PUSH_NOTIFICATIONS = 'PushNotifications'
export const REPORT_ISSUE = 'ReportIssue'
export const FEEDBACK = 'Feedback'
export const REQUEST_UPDATE_ORDER = 'RequestUpdateOrder'
export const REQUEST_STOCK_TRANSFER = 'RequestStockTransfer'
export const REPORT_STOCK_DIFFERENCE = 'ReportStockDifference'
export const REQUEST_UPDATE_ACCESS_POINT_CONTACT_NOTIFICATION = 'RequestUpdateAccessPointContactNotification'
export const ALL_TYPE_OPTIONS = [STOCK_COUNT, EMAIL, SMS, SENT_FEEDBACK, PUSH_NOTIFICATIONS]
export const FEEDBACK_TYPE_OPTIONS = [FEEDBACK, REPORT_ISSUE, REQUEST_UPDATE_ORDER, REQUEST_STOCK_TRANSFER, REPORT_STOCK_DIFFERENCE, REQUEST_UPDATE_ACCESS_POINT_CONTACT_NOTIFICATION]

export const PLANT = 'Plant'
export const STORAGE_LOCATION = 'Storage Location'
export const EQUIPMENT_CODE = 'Equipment Code'
export const EQUIPMENT_DESCRIPTION = 'Equipment Description'
export const WORK_CENTER = 'Work Center'
export const PLANNER_GROUP = 'Planner Group'
export const TRACKING_NUMBER = 'Tracking number'
export const PURCHASE_ORDER = 'Purchase Order'
export const SALES_ORDER = 'Sales Order'
export const DELIVERY_ORDER = 'Delivery Order'
export const SERVICE_ORDER = 'Service Order'
export const MATERIAL_CODE = 'Material Code'
export const MATERIAL_DESCRIPTION = 'Material Description'
export const DELIVERY_DATE = 'Delivery Date'
export const PHONE_NUMBER = 'Phone Number'
export const CREATED = 'Created'
export const MESSAGE = 'Message'
export const SUBJECT = 'Subject'
export const TITLE = 'Title'

export const I_DONT_KNOW = 'I don\'t know'

export const FORM_STORAGE_LOCATION = 'storageLocation'
export const FORM_PLANT = 'plant'
export const FORM_MATERIAL_CODE = 'materialCode'
export const FORM_COMMENT = 'comment'
export const FORM_UAP1 = 'uap1'
export const FORM_UAP2 = 'uap2'
export const FORM_UAP3 = 'uap3'

export const FORM_REPORT_ISSUE_NAME = 'Report issue'
export const FORM_REQUEST_STOCK_TRANSFER_NAME = 'Request Stock transfer'
export const FORM_REQUEST_UPDATE_ORDER_NAME = 'Request update ROP or ROQ'
export const FORM_REPORT_STOCK_DIFFERENCE_NAME = 'Report stock difference'
export const FORM_RATING_FEEDBACK_NAME = 'Rating Feedback'
export const FORM_REQUEST_UPDATE_ACCESS_POINT_CONTACT_NOTIFICATION_NAME = 'Request update on access point selection contact'

export const FORM_REPORT_ISSUE_TYPE = 'report_issue'
export const FORM_REQUEST_STOCK_TRANSFER_TYPE = 'request_stock_transfer'
export const FORM_REQUEST_UPDATE_ROP_ROQ_TYPE = 'request_update_ROP_ROQ'
export const FORM_REPORT_STOCK_DIFFERENCE_TYPE = 'report_stock_difference'
export const FORM_RATING_FEEDBACK_TYPE = 'rating_feedback'
export const FORM_REQUEST_UPDATE_ACCESS_POINT_CONTACT_NOTIFICATION_TYPE = 'request_access_permission_contact_notification'
export const ALL_TYPE = 'all'

export const NOTIFICATION_DELIVERY_NOTIFICATION_FILTER_TYPE = 'delivery_notification'
export const NOTIFICATION_INVENTORY_COUNT_NOTIFICATION_FILTER_TYPE = 'inventory_count_notification'
export const NOTIFICATION_CONTACT_NOTIFICATION_FILTER_TYPE = 'contact_notification'
export const NOTIFICATION_OTHER_NOTIFICATION_FILTER_TYPE = 'other_notification'

export const FEEDBACK_FILTER_KEY = 'feedback_type'
export const NOTIFICATION_FILTER_KEY = 'notification_type'

export const YES = 'Yes'
export const NO = 'No'
export const NEVER = 'Never'
