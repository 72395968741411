import axios from 'axios'
import uniqueOnly from '@/utils/unique-only'
import {
  MY_STOCK,
  MY_TEAM_STOCK,
  MY_PLANT_STOCK,
  ALL_TYPE_OPTIONS
} from '@/constants/stock-management'
import { stockManagementSearchHistory } from '@/utils/search-history'

const DEFAULT_MAX_RESULTS = 20

function validType (type) {
  const allowedTypes = ALL_TYPE_OPTIONS
  return allowedTypes.includes(type)
}

/**
 * List stock items from Stock API.
 *
 * @param {Object} queryParameters
 * @param {string[]} queryParameters.query - Free text search strings. If empty,
 *   the stock items are listed without using search in the backend.
 * @param {string} queryParameters.type - Type of stock item. @see '../constants/stock-management.js'
 * @param {number} queryParameters.maxResults - (Integer) Limit how many items are at most
 *   in the response.
 * @param {number} queryParameters.page (Integer) current page index in stock items array. Add one to get the database page.
 */
function listStockItems (queryParameters) {
  const parameters = ['query', 'type', 'maxResults', 'page']
  parameters.forEach((parameter) => {
    // Check that caller has not forgot to include required parameter.
    if (queryParameters[parameter] === undefined) {
      throw Error(`Missing parameter ${parameter} in listStockItems`)
    }
  })
  const { type, ...params } = queryParameters
  if (!validType(type)) {
    throw Error(`Invalid value "${type}" for parameter type`)
  }

  let endpoint
  switch (type) {
    case MY_STOCK:
      endpoint = '/api/v2/my_stock'
      break
    case MY_TEAM_STOCK:
      endpoint = '/api/v2/my_team_stock'
      break
    case MY_PLANT_STOCK:
      endpoint = '/api/v2/my_plant_stock'
      break
  }

  const url = (
    process.env.VUE_APP_STOCK_API_ENDPOINT +
    endpoint
  )
  const requestPromise = axios.get(url, { params })
    .catch((error) => {
      console.error({ _: 'listStockItems failed', queryParameters, url, error })
      throw error
    })
  return requestPromise
}

function parseBackendStockItems (item) {
  const truthyOnly = values => values.filter(value => value)
  return {
    bin: item.bin,
    blockedCount: item.blocked_count,
    materialCode: item.material_code,
    materialDescriptionEnglish: item.material_description_english,
    plannerGroups: truthyOnly(uniqueOnly(item.planner_groups || [])),
    plant: item.plant,
    quantityInspectionCount: item.quantity_inspection_count,
    reorderPoint: item.reorder_point,
    reorderQuantity: item.reorder_quantity,
    restrictedCount: item.restricted_count,
    storageLocation: item.storage_location,
    transferCount: item.transfer_count,
    unrestrictedCount: item.unrestricted_count,
    workCenters: truthyOnly(uniqueOnly(item.work_centers || [])),
    get quantity () {
      if (
        typeof this.blockedCount === 'number' &&
        typeof this.quantityInspectionCount === 'number' &&
        typeof this.restrictedCount === 'number' &&
        typeof this.transferCount === 'number' &&
        typeof this.unrestrictedCount === 'number'
      ) {
        return (
          this.blockedCount +
          this.quantityInspectionCount +
          this.restrictedCount +
          this.transferCount +
          this.unrestrictedCount
        )
      } else {
        return null
      }
    }
  }
}

export default {
  namespaced: true,
  state: {
    page: 0,
    firstFetchDone: false, // True after the first stock items listing request has been made
    ongoingQuery: false,
    userSearchOptions: {
      type: MY_STOCK,
      maxResults: DEFAULT_MAX_RESULTS
    },
    searchParts: [], // This stores the user submitted free text search.
    stockItems: []
  },
  getters: {
    /**
     * Has there been at least one call to Stock API for listing stock items
     *
     * This helps to determine when the page is loaded and search should or should not be
     * made without user action.
     *
     * @param {Object} state - Vuex state for getters.
     * @returns {boolean} - True after first request to Stock API is finished.
     */
    firstFetchDone: (state) => {
      return state.firstFetchDone
    },
    /**
     * Indicates if front-end is currently calling Stock API to list stock items
     *
     * @param {Object} state - Vuex state for getters.
     * @returns {boolean} - True if request has been made but response has not arrived.
     */
    ongoingQuery: (state) => {
      return state.ongoingQuery
    },
    searchParts: (state) => {
      return state.searchParts
    },
    stockItems: (state) => (page) => {
      return state.stockItems[page]?.stockItems ?? []
    },
    mobileStockItems: (state) => {
      let mobileItems = []
      for (const itemPage of state.stockItems) {
        mobileItems = [...mobileItems, ...itemPage.stockItems]
      }
      return mobileItems
    },
    userSearchOptions: (state) => {
      return state.userSearchOptions
    },
    page: (state) => {
      return state.page
    },
    lastPage: (state) => {
      if (state.stockItems.length === 0) {
        return null
      }
      const lastPage = state.stockItems?.[state.stockItems.length - 1]
      return lastPage
    },
    hasNext: (state, getters) => {
      const currentPage = state.stockItems[getters.page]
      if (currentPage) {
        return currentPage.hasNext
      }
      return false
    },
    hasPrev: (state, getters) => {
      const currentPage = state.stockItems[getters.page]
      if (currentPage) {
        return currentPage.hasPrev
      }
      return false
    },
    storageLocationCount: (state) => {
      // `storageLocationCount` is only on the first page (for efficiency).
      return state.stockItems?.[0]?.storageLocationCount ?? null
    }
  },
  mutations: {
    addStockManagementPage (state, { queryParameters, response }) {
      const matchingQueryPages = state.stockItems.filter((page) => page.query === queryParameters.query)
        .filter((page) => page.type === queryParameters.type)
        .filter((page) => page.maxResults === queryParameters.maxResults)
      if (matchingQueryPages.length > 0) {
        const matchingPage = matchingQueryPages.find(page => page.page === queryParameters.page)
        if (matchingPage) {
          throw Error('addStockManagementPage tried to add a page that has already been fetched. Cache mismatch.')
        }
      }
      const newPage = {
        index: state.stockItems.length,
        query: queryParameters.query,
        type: queryParameters.type,
        page: response.data.page,
        pages: response.data.pages,
        hasNext: response.data.has_next,
        hasPrev: response.data.has_prev,
        storageLocationCount: response.data.storage_location_count,
        stockItems: response.data.stock_items.map(parseBackendStockItems)
      }
      state.stockItems.push(newPage)
      state.page = newPage.index
    },
    clearStockItems (state) {
      state.stockItems = []
    },
    startQuery (state) {
      state.ongoingQuery = true
    },
    finishQuery (state) {
      state.firstFetchDone = true // This is set to false only in the "page load" (default)
      state.ongoingQuery = false
    },
    setSearchParts (state, searchParts) {
      state.searchParts = searchParts ?? []
    },
    setSearchOptionType (state, type) {
      if (!validType(type)) {
        throw Error(`Invalid value ${type} for search option "type"`)
      }
      state.userSearchOptions = {
        ...state.userSearchOptions,
        type
      }
    },
    setPage (state, page) {
      if (page === undefined || page === null) {
        throw Error('Missing required parameter "page" in setPage')
      }
      if (page !== parseInt(page)) {
        // Rudimentary value check
        throw Error(`Invalid value ${page} for page parameter in setPage`)
      }
      if (page > state.stockItems.length) {
        throw Error(`Provided page value ${page} is out of bound for last page index ${state.stockItems.length}`)
      }
      state.page = page
    }
  },
  actions: {
    /**
     * List stock items from Stock API.
     *
     * Use `search` action when possible. This is just a wrapper
     * to `listStockItems` function that sets the `ongoingQuery` flag and puts
     * the results to the `stockItems` collection.
     *
     * @see search
     * @param {ActionContext} [vuexContext]
     * @returns {Promise} - Raw Stock response from the backend
     *  (passed by listStockItems function)
     */
    fetchPage ({ commit }, queryParameters) {
      commit('startQuery')
      const requestPromise = listStockItems(queryParameters)
        .then((response) => {
          commit('addStockManagementPage', { queryParameters, response })
          return response
        })
        .catch(error => {
          commit('setErrorMessage', error?.response?.data?.message ?? error, { root: true })
        })
        .finally(() => {
          commit('finishQuery')
        })
      return requestPromise
    },
    /**
     * Fetch the next page using the page information from last response
     *
     * If there is at least one page and that page hasNext is true (meaning that there are more
     * pages to fetch), then we can fetch next page and continue the pagination.
     *
     * @see listTrackingStatuses
     *
     * @param {ActionContext} [vuexContext]
     * @returns {Promise} - Raw Tracking Status response from the backend
     *  (passed by fetchPage action)
     */
    fetchNextPage ({ state, dispatch, getters }, { maxResults }) {
      if (state.stockItems.length === 0) {
        // TODO: This is a placeholder to test the first fetch. Include all options in future.
        return dispatch('search', { maxResults })
      }
      const queryParameters = {
        query: getters.lastPage.query ?? [],
        page: getters.lastPage.page + 1,
        type: getters.lastPage.type,
        maxResults: maxResults ?? getters.lastPage.maxResults ?? DEFAULT_MAX_RESULTS
      }
      return dispatch('fetchPage', queryParameters)
    },
    /**
     * @param {ActionContext} [vuexContext]
     * @param {Object} payload
     * @param {string[]|null} [payload.query] - Free text search strings. If empty or null,
     *   the stock items are listed by my_stock, my_team_stock and my_plant_stock
     * @param {number} [payload.maxResults] Limit how many items are at most in the response.
     *   Default value is using userSearchOptions state.
     * @param {boolean} [payload.saveToSearchHistory=true] - Save free text used for search to local storage.
     * @param {string} [type]
     * @returns {Promise} Raw Stock Management response from the backend
     */
    search (
      { state, getters, commit, dispatch },
      { query, maxResults, saveToSearchHistory = true, type } = {}
    ) {
      if (getters.ongoingQuery) {
        // When calling this action check that there is no ongoing query.
        // TODO: There should be something to reset ongoingQuery if something goes
        // wrong. Like the response never arrives. Add timeout check or something
        // to the axios call.
        console.error({
          _: 'stockManagement/search action called when query was ongoing',
          query,
          maxResults
        })
        throw Error('Query is still in progress!')
      }
      const queryParameters = {
        query,
        type: type ?? state.userSearchOptions.type,
        maxResults: maxResults ?? state.userSearchOptions.maxResults,
        page: 1 // search returns first page
      }
      commit('clearStockItems')
      if (saveToSearchHistory && query?.length) {
        stockManagementSearchHistory.add(query.join(' '))
      }
      // Store the search free text for showing indicator that the results are
      // from a search. Query can be empty.
      commit('setSearchParts', query)
      return dispatch('fetchPage', queryParameters)
    }
  }
}
