export const UNKNOWN = 'Unknown'
export const WINDOWS = 'Windows'
export const MACINTOSH = 'Macintosh'
export const LINUX = 'Linux'
export const ANDROID = 'Android'
export const IOS = 'iOS'
export const OPERA = 'Opera'
export const EDGE = 'Edge'
export const CHROME = 'Chrome'
export const SAFARI = 'Safari'
export const FIREFOX = 'Firefox'
export const IE = 'IE'

export const DEVICE_ID = 'deviceId'
export const FCM_TOKEN = 'fcmToken'
