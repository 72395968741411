import { CUSTOMER_DOMAINS } from '@/constants/shared-constants'

export function validateEmail (value) {
  /**
   * This function will return a boolean value indicating whether the email is valid.
   * @param {*} value
   * @returns boolean
  */
  if (!value) return false

  const domainString = CUSTOMER_DOMAINS.join('|')
  const emailRegex = new RegExp(
    // eslint-disable-next-line no-useless-escape
    `^\\s*([a-zA-Z0-9._%+-]+)@(${domainString})\\s*$`
  )
  return emailRegex.test(value)
}

export function validateMobile (value) {
  /**
   * This function will return a boolean value indicating whether the mobile is valid.
   * @param {*} value
   * @returns boolean
  */
  if (!value) return false
  // eslint-disable-next-line
  const mobileRegex = new RegExp(
    // eslint-disable-next-line
    '^\\s*(\\+[0-9]{3,})\\s*$'
  )
  return mobileRegex.test(value)
}

export function validateDate (value) {
  /**
   * This function will return a boolean value indicating whether the date is valid.
   * date format: YYYY/MM/DD or DD-MM-YYYY or DD.MM.YYYY or DD\MM\YYYY or DD\MM\YYYY
   * @param {*} value
   * @returns boolean
   */
  if (!value) return false
  // eslint-disable-next-line
  const dateRegex = new RegExp(
    '^\\s*(([0-9]{4}[\\/\\.\\\\-]?[0-9]{2}[\\/\\.\\\\-]?[0-9]{2})|([0-9]{2}[\\/\\.\\\\-]?[0-9]{2}[\\/\\.\\\\-]?[0-9]{4}))\\s*$'
  )
  // validate that the MM is not greater than 12 and the DD is not greater than 31
  if (dateRegex.test(value)) {
    const date = value.split(/[\\/\\.\\\\-]/)
    const month = date[1]
    const day = date[0].length === 4 ? date[2] : date[0]
    if (month > 12 || day > 31 || month < 1 || day < 1) {
      return false
    }
    return true
  }
  return false
}

export function validateMobileInSearch (value) {
  /**
   * This function will return a boolean value indicating whether the mobile is valid where '+' is optional.
   * @param {*} value
   * @returns boolean
  */
  if (!value) return false
  // eslint-disable-next-line
  const mobileRegex = new RegExp(
    '^\\s*(\\+?[0-9]{3,})\\s*$'
  )
  return mobileRegex.test(value)
}
