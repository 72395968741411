/*
  * This module is a generic pagination module that can be used to fetch paginated data from a backend.
  * TODO: Add support for filtering and sorting.
*/
import axios from 'axios'
import { DESC, ASC } from '@/constants/shared-constants'
const DEFAULT_MAX_RESULTS = 20
/*
  * @param {string} params.endpoint - The endpoint to fetch data from.
  * @param {function} params.mapper - A function that maps the data from the backend to the frontend.
  * @returns {object} A Vuex module.
*/
export default function createPaginationModule ({ endpoint, mapper }) {
  const actualMapper = mapper ?? (item => item)

  function listItems (queryParameters, searchString) {
    const parameters = ['page', 'maxResults']
    parameters.forEach((parameter) => {
      // Check that caller has not forgot to include required parameter.
      if (queryParameters[parameter] === undefined) {
        throw Error(`Missing parameter ${parameter} in listItems`)
      }
    })
    let url = endpoint
    if (searchString) {
      url += `?${searchString}`
    }
    const requestPromise = axios.get(url, { params: queryParameters })
      .catch((error) => {
        throw error
      })
    return requestPromise
  }

  return {
    state: {
      pages: [],
      searchString: '',
      page: 0,
      ongoingQuery: false,
      sortDirection: DESC,
      orderByColumn: null

    },

    mutations: {
      addPage (state, response) {
        const newPage = {
          index: state.pages.length,
          // query: queryParameters.query,
          // type: queryParameters.type,
          page: response.response.data.page,
          pages: response.response.data.pages,
          hasNext: response.response.data.has_next,
          hasPrev: response.response.data.has_prev,
          items: response.response.data.items
        }
        state.pages.push(newPage)
      },
      setPages (state, pages) {
        state.pages = pages
      },
      setPage (state, page) {
        state.page = page
      },
      startQuery (state) {
        state.ongoingQuery = true
      },
      finishQuery (state) {
        state.ongoingQuery = false
      },
      setSearchString (state, searchString) {
        state.searchString = searchString
      },
      setOrderByColumn (state, value) {
        state.orderByColumn = value
      },
      setSortDirection (state, value) {
        if (value) { // false is ascending and true is dscending order
          state.sortDirection = DESC
        } else {
          state.sortDirection = ASC
        }
      },
      clearPage (state) {
        state.page = 0
        state.pages = []
        state.searchString = ''
      }
    },

    getters: {
      items: state => state.pages?.[state.page - 1]?.items.map(actualMapper),
      mobileItems: (state) => {
        let mobileItems = []
        for (const itemPage of state.pages) {
          mobileItems = [...mobileItems, ...itemPage.items]
        }
        return mobileItems.map(actualMapper)
      },
      page: state => state.page,
      lastPage: state => state.pages.length - 1,
      firstFetchDone: state => state.pages.length > 0,
      hasNext: state => state.pages?.[state.page - 1]?.hasNext,
      hasPrev: state => state.pages?.[state.page - 1]?.hasPrev,
      ongoingQuery: state => state.ongoingQuery,
      searchString: state => state.searchString,
      sortDirection (state) {
        if (state.sortDirection === DESC) { // false is ascending and true is descending order
          return true
        }
        return false
      },
      orderByColumn (state) {
        return state.orderByColumn
      }
    },

    actions: {
      fetchNextPage ({ getters, commit }) {
        if (getters.lastPage >= getters.page) {
          commit('setPage', getters.page + 1)
          return
        }
        const queryParameters = {
          page: getters.page + 1,
          maxResults: DEFAULT_MAX_RESULTS
        }
        if (getters.orderByColumn) {
          queryParameters.orderByColumn = getters.orderByColumn
          queryParameters.sortDirection = getters.sortDirection ? DESC : ASC
        }
        commit('startQuery')
        const requestPromise = listItems(queryParameters, getters.searchString)
          .then((response) => {
            commit('addPage', { response })
            commit('setPage', getters.page + 1)
            return response
          })
          .catch(error => {
            commit('setErrorMessage', error?.response?.data?.message ?? error, { root: true })
          })
          .finally(() => {
            commit('finishQuery')
          })
        return requestPromise
      },
      fetchPrevPage ({ state, commit }, maxResults) {
        if (state.page <= 0) {
          return
        }
        commit('setPage', state.page - 1)
      },
      startSearch ({ commit, dispatch }, searchString) {
        commit('clearPage')
        commit('setSearchString', searchString)
        dispatch('fetchNextPage')
      }
    }
  }
}
